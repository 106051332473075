<template>
  <outside-click-listener
    @outside-click="emit('close')"
  >
    <div
      ref="filterBoxRef"
      :style="{
        top: `${filterBoxPosition.top}px`,
        left: `${filterBoxPosition.left}px`
      }"
      class="FilterPopup"
    >
      <div class="FilterPopup-attributeFilter-header">
        <div class="FilterPopup-attributeFilter-title">
          <span class="FilterPopup-attributeFilter-title-span"> {{ attributeFilterTitle }} </span>
        </div>
        <iob-dropdown-button
          class="FilterPopup-attributeFilter-button"
          size="medium"
          color="primary"
          button-type="ghost"
          icon-name="Plus"
          :show-left-icon="true"
          :show-right-icon="false"
          :title="$t('filter.addFilter')"
          :type-check="false"
          :items="attributeFiltersList"
          :is-scrollable="true"
          :dropdown-style="{
            maxHeight: '500px',
            left: '-51px',
            top: '42px'
          }"
          @dropdown-element-click="(item) => handleAddFilter(item)"
        />
      </div>
      <div class="FilterPopup-attributeFilter-container">
        <div
          v-for="(filter, filterKey) in attributeFilterData"
          :key="filterKey"
          class="FilterPopup-attributeFilter-container-filter"
        >
          <div class="FilterPopup-attributeFilter-container-filter-content">
            <icon-loader
              :name="attributeFilters[filterKey].iconName"
              color="#47435F"
            />
            <span class="FilterPopup-attributeFilter-container-filter-content-span">
              {{ attributeFilters[filterKey].text }}
            </span>
          </div>
          <iob-multiple-select
            :dropdown-items="filter.options"
            :selected-items="filter.selectedValues"
            size="xs"
            color="secondary"
            :has-dynamic-search="filter.hasDynamicSearch"
            is-scrollable
            :has-single-select="filter.isSingleSelect"
            :max-displayed-items="filter.maxDisplayedItems"
            dropdown-style="max-height: 250px; z-index:1"
            @on-click-item="(item) => handleOnClickItem(item, filter.hasSingleSelect)"
            @on-delete-badge="(item) => handleSelectTreeItem(item, filter, filterKey)"
            @update:inputValue="(value) => emit('update:inputValue', {value, componentType: filter.componentType})"
          >
            <tree-view
              v-if="filter.treeValues"
              :tree-data="filter.options"
              :label-field="filter.treeValues.labelField"
              :multiple-select-tree-values="filter.selectedValues"
              :is-multiple-select="true"
              show-left-icon
              :show-right-icon="true"
              :is-dropdown-opened="true"
              :show-label-field="filter.treeValues.showLabelField"
              dropdown-style="width: 100%; left: 0"
              @select-multiple-items="(item) => handleSelectTreeItem(item, filter, filterKey)"
            />
          </iob-multiple-select>
          <Iob-action-button
            size="default"
            color="secondary"
            type="ghost"
            icon-name="Minus"
            @click="() => handleRemoveFilter(filterKey)"
          />
        </div>
      </div>
    </div>
  </outside-click-listener>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
  attributeFilterData: {
    type: Object,
    default: () => ({})
  },
  anchorEl: {
    type: Object,
    default: () => null
  },
  attributeFilterTitle: {
    type: String,
    default: ''
  },
  attributeFilters: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['close', 'add-filter', 'remove-filter']);
const filterBoxRef = ref(null);
const attributeFiltersList = computed(() => Object.values(props.attributeFilters).filter(((attrbute) => !attrbute.selected)));

const filterBoxPosition = ref({ top: 0, left: 0 });

const computeFilterBoxPosition = () => {
  if (props.anchorEl) {
    filterBoxPosition.value = {
      left: props.anchorEl.offsetLeft - filterBoxRef.value.offsetWidth + props.anchorEl.offsetWidth,
      top: props.anchorEl.offsetTop + props.anchorEl.offsetHeight
    };
  }
};

onMounted(() => {
  window.addEventListener('resize', computeFilterBoxPosition);
  computeFilterBoxPosition();
});

onUnmounted(() => {
  window.removeEventListener('resize', computeFilterBoxPosition);
});

const handleAddFilter = (item) => {
  emit('add-filter', item);
};

const handleRemoveFilter = (item) => {
  emit('remove-filter', item);
};

const handleSelectTreeItem = (item, filter, filterKey) => {
  emit('select-tree-item', item, filter, filterKey);
};
</script>
  <style lang="scss" scoped>
  .FilterPopup {
    display: inline-flex;
    width: var(--app-template-filter-size-max-width);
    padding: var(--app-template-filter-padding-vertical) var(--app-template-filter-padding-horizontal);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--app-template-filter-gap-large);
    border-radius: var(--app-template-filter-radius-default);
    border: var(--app-template-filter-size-border) solid var(--app-template-filter-color-border-container);
    background: var(--app-template-filter-color-background-container);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 5%);
    background-color: white;
    position: absolute;
    z-index: 1000;
    margin-top: 8px;

    &-attributeFilter {
        &-header {
            display: flex;
            align-items: center;
            gap: var(--app-template-filter-gap-small);
            align-self: stretch;
        }

        &-title {
            flex: 1 0 0;

            &-span {
                color: var(--app-template-filter-color-text-title);
                font-family: "Noto Sans", sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }

        &-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--app-template-filter-gap-default);
            align-self: stretch;

            &-filter {
            display: flex;
            align-items: center;
            gap: var(--app-template-filter-gap-large);
            align-self: stretch;

                &-content {
                display: flex;
                width: 120px;
                align-items: center;
                gap: 8px;

                    &-span {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        flex: 1 0 0;
                        overflow: hidden;
                        color: var(--app-template-filter-color-text-label);
                        text-overflow: ellipsis;
                        font-family: "Noto Sans", sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        letter-spacing: 0.14px;
                    }
                }

            }
        }

    }

  }
  </style>
