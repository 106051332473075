import {UNASSIGNED, APEX_TREE} from 'GLOBALS/constants';

export const initializeHierarchyColors = (hierarchy, colors, index = 0, isFirstLevel = true) => {
  if (!hierarchy) {
    return;
  }
  if (isFirstLevel) {
    hierarchy.attributes.colors = colors[index % colors.length];
    index++;
  }
  hierarchy.children.forEach((child) => {
    child.attributes.colors = colors[index % colors.length];
    initializeHierarchyColors(child, colors, index + 1, false);
  });
};

export const getFilterTreeValues = (attValues, options, item) =>  attValues.map((elemID) => {
  const selectedItem = findItemInTree(options, elemID);
  return selectedItem
    ? {
      name: item.name,
      id: selectedItem.id,
      text: selectedItem.attributes[item.treeValues.labelField],
      checked: true,
      type: 'menu',
      componentType: 'tree',
      withID: true
    }
    : {};
});

export const findItemInTree = (items, id) => {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      const found = findItemInTree(item.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const getLevelAttributeValue = (levels, id) => {
  const levelName = 'level-name';
  if (levels?.length) {
    const level = findItemInTree(levels, id);
    if (level) {
      return {
        id: level.id,
        [levelName]: level.attributes[levelName],
        colors: level.attributes.colors || {}
      };
    }
  }
  return {
    [levelName]: UNASSIGNED
  };
};

export const truncateText = (text, maxLength) => {
  if (!text) {
    return '';
  }
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const getApexTreeOptions = (options = {}) => {
  const {
    contentKey = APEX_TREE.OPTIONS.CONTENT_KEY,
    width = APEX_TREE.OPTIONS.WIDTH,
    height = APEX_TREE.OPTIONS.HEIGHT,
    nodeWidth = APEX_TREE.OPTIONS.NODE_WIDTH,
    nodeHeight = APEX_TREE.OPTIONS.NODE_HEIGHT,
    fontColor = APEX_TREE.OPTIONS.FONT_COLOR,
    borderColor = APEX_TREE.OPTIONS.BORDER_COLOR,
    borderRadius = APEX_TREE.OPTIONS.BORDER_RADIUS,
    childrenSpacing = APEX_TREE.OPTIONS.CHILDREN_SPACING,
    siblingSpacing = APEX_TREE.OPTIONS.SIBLING_SPACING,
    direction = APEX_TREE.OPTIONS.DIRECTION,
    enableExpandCollapse = APEX_TREE.OPTIONS.ENABLE_EXPAND_COLLAPSE,
    canvasStyle = APEX_TREE.OPTIONS.CANVAS_STYLE,
    enableToolbar = APEX_TREE.OPTIONS.ENABLE_TOOLBAR
  } = options;
  return {
    contentKey, width, height, nodeWidth, nodeHeight, fontColor, borderColor, borderRadius,
    childrenSpacing, siblingSpacing, direction, enableExpandCollapse, canvasStyle, enableToolbar
  };
};

const utils = {
  debounce: (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  },
  initializeHierarchyColors,
  truncateText,
  getApexTreeOptions
};
export default utils;
