<template>
  <div
    class="SnackBar"
  >
    <div class="SnackBar-content">
      <icon-loader
        name="CheckCircle-2"
        size="small"
        color="#ffffff"
      />
      <p class="SnackBar-text">
        {{ props.message }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { defineProps, watch } from 'vue';
import { useAppStore } from 'SRC/piniaStore/app/app';

const props = defineProps({
  message: {
    type: String,
    default: ''
  },
  show: {
    type: Boolean,
    default: false
  }
});

const closeSnackbar = () => {
  useAppStore().setNotificationDisplayed({show: false, message: ''});
};

watch(() => props.show, (newShowValue) => {
  if (newShowValue) {
    setTimeout(() => {
      closeSnackbar();
    }, 3000);
  }
}, { immediate: true, deep: true });
</script>

<style scoped lang="scss" src="./SnackBar.scss" />

