<template>
  <iob-table
    :table-headers="dataViewHeadersConfig.headers"
    :rows="formattedData"
    :has-vertical-separator="true"
    :check-all="checkAll"
    :enable-greyed-out="true"
    :search-data="searchInput"
    @on-click-cell-item="handelCellItemClick"
    @on-click-table-row="(index) => handleTableRowClick(index)"
    @toggle-check-all="handleToggleCheckAll"
  />
</template>

<script setup>
import { computed, defineProps, watch, defineEmits, ref } from 'vue';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useEditorStore } from 'SRC/piniaStore/editor/editor';
import { DATA_VIEW_HEADERS_CONFIG } from 'SRC/globals/constants';
import { formatElement } from 'SRC/views/DataView/utils';

const dataStore = useDataStore();
const editorStore = useEditorStore();
const appStore = useAppStore();
const usersStore = useUsersStore();

const dataViewHeadersConfig = ref(DATA_VIEW_HEADERS_CONFIG);

const datasetElements = computed(() => dataStore.datasetElements);
const users = computed(() => usersStore.users);
const datatypes = computed(() => appStore.datatypes);

const formattedData = ref([]);
const selectedDatasetElements = computed(() => dataStore.selectedDatasetElements);
const checkAll = computed(() => {
  if (selectedDatasetElements.value && selectedDatasetElements.value.length === 0) {
    return false;
  }
  return selectedDatasetElements.value.length === Object.values(dataStore.datasetElements).length;
});

const emits = defineEmits(['data-change']);

defineProps({
  searchInput: {
    type: String,
    default: ''
  }
});

watch(
  () => selectedDatasetElements.value,
  (newSelectedData) => {
    formattedData.value.map((row) => {
      const selectedId = newSelectedData.find((id) => id === row.TYPE.id);
      row.TYPE.checked = selectedId ? true : false;
      return row;
    });
    emits('data-change', formattedData.value);
  }, { deep: true }
);

const formatData = (newDatasetElements) => {
  if (Object.values(datatypes.value).length) {
    formattedData.value = Object.values(newDatasetElements).map(({ attributes, typeId }) =>
      formatElement({users, datatypes, selectedDatasetElements, attributes, typeId})
    );
    emits('data-change', formattedData.value);
  }
};

watch(() => datasetElements.value, formatData, { deep: true });

const handelCellItemClick = async ({ item }) => {
  if (item && item.name === 'checkbox') {
    const selectedDataElement = Object.values(datasetElements.value).find(
      (el) => el.attributes['friendly-id'] === item.id
    );
    const id = selectedDataElement?.attributes['friendly-id'];
    if (!item.isChecked && id) {
      await dataStore.removeSelectedDatasetElements(id);
      return;
    }
    await dataStore.addSelectedDatasetElements([item.id]);
  }
};

const handleTableRowClick = (index) => {
  const datasetElement = Object.values(dataStore.datasetElements)[index];
  editorStore.openEditor(datasetElement.id);
};

const handleToggleCheckAll = (item) => {
  if (item.isChecked) {
    const ids = Object.values(dataStore.datasetElements).map((el) => el.attributes['friendly-id']);
    dataStore.addSelectedDatasetElements(ids);
    return;
  }
  dataStore.clearSelectedDatasetElements();
};

</script>

<style lang="scss" scoped src="../DataTable.scss" />
