<template>
  <div>
    <LoadingPageLayer
      v-if="isInProgress"
      size="extraLarge"
      spinner-color="blue"
    />
    <div
      v-else
      class="ImportHierarchyStep-step"
    >
      <div class="ImportHierarchyStep-step-text">
        <div class="ImportHierarchyStep-step-title">
          Step {{ stepNumber }}
        </div>
        <div class="ImportHierarchyStep-step-description">
          {{ description }}
        </div>
      </div>
      <div
        v-if="isDownload"
        class="ImportHierarchyStep-download-container"
      >
        <div class="ImportHierarchyStep-download-button">
          <div class="ImportHierarchyStep-download-icon">
            <IconLoader
              name="Download"
              color="white"
            />
          </div>
          <div class="ImportHierarchyStep-download-link">
            <button
              class="ImportHierarchyStep-download-link-button"
              @click="exportHierarchyTemplate"
            >
              Download template (.xls)
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="isImport"
        class="ImportHierarchyStep-import-container"
        @dragover.prevent
        @drop.prevent="handleDrop"
      >
        <div class="ImportHierarchyStep-import-box">
          <div class="ImportHierarchyStep-import-text">
            <input
              ref="fileInput"
              type="file"
              hidden
              @change="handleFileUpload"
            >
            <div class="ImportHierarchyStep-import-icon">
              <IconLoader
                name="CloudUpload"
                color="blue"
              />
            </div>
            <div class="ImportHierarchyStep-import-instructions">
              <div class="ImportHierarchyStep-import-instructions-div">
                Drag & drop an Excel file or
              </div>
              <button
                class="ImportHierarchyStep-choose-file"
                @click="triggerFileInput"
              >
                choose a file
              </button>
              <div class="ImportHierarchyStep-import-instructions-div">
                to upload
              </div>
            </div>
            <div
              v-if="errorMessage"
              class="ImportHierarchyStep-error-message"
            >
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter  } from 'vue-router';
import { defineProps, ref, onMounted, computed, watch } from 'vue';
import {DATA_TYPES_NAMES, HIERARCHY} from 'GLOBALS/constants';
import { useAppStore } from 'SRC/piniaStore/app/app';
import  templateHierarchy  from 'SRC/assets/templates/Template-hierarchy.xlsx';

const appStore = useAppStore();
const levelDataTypeId = ref(null);
const file = ref(null);
const errorMessage = ref('');
const isInProgress = ref(false);
const router = useRouter();
const excelFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

defineProps({
  stepNumber: { type: Number, default: 1 },
  description: { type: String, default: '' },
  isDownload: { type: Boolean, default: false },
  isImport: { type: Boolean, default: false }
});

const systemDatatypes = computed(() => appStore.systemDatatypes);

const fetchLevelDataTypeId = async () => {
  const levelDataType = Object.values(systemDatatypes.value).find((element) => element.name === DATA_TYPES_NAMES.LEVEL);
  return levelDataType ? levelDataType.id : '';
};

watch(() => systemDatatypes.value,
  async () => {
    levelDataTypeId.value = await fetchLevelDataTypeId();
  }, { deep: true, immediate: true}
);

onMounted(async () => {
  file.value = document.querySelector('input[type="file"]');
});

const triggerFileInput = () => file.value.click();

const handleFileUpload = (event) => {
  file.value = event.target.files[0];
  validateAndUploadFile();
};

const handleDrop = (event) => {
  file.value = event.dataTransfer.files[0];
  validateAndUploadFile();
};

const resetComponent = () => {
  file.value = null;
  errorMessage.value = '';
};

const validateAndUploadFile = async () => {
  isInProgress.value = true;
  if (!file.value || !excelFileTypes.includes(file.value.type)) {
    errorMessage.value = 'Please upload a valid Excel file.';
    return;
  }
  errorMessage.value = '';
  await uploadFile();
  isInProgress.value = false;
  await router.push(HIERARCHY.VIEW_PATH);
};

const uploadFile = async () => {
  const formData = new FormData();
  formData.append('file', file.value, 'TemplateHierarchy.xlsx');
  formData.append('dataTypeId', levelDataTypeId.value);
  const transformBodyFn = (body) => body;

  const isSuccessful = await appStore.ImportExcelFile(formData, transformBodyFn);
  if (isSuccessful) {
    resetComponent();
  }
};

const exportHierarchyTemplate = async () => {
  await appStore.exportFile(templateHierarchy, 'Template-hierarchy.xlsx', 'vnd.ms-excel', true);
};
</script>

<style lang="scss" scoped src="./ImportHierarchyStep.scss" />
