<template>
  <simple-popup
    content="Please verify that the URL is valid, or directly access the following page:"
    title="Sorry, this page cannot be found"
    button-label="List of boards"
    @button-click="navigateToBoards"
  />
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    navigateToBoards() {
      this.$router.push('/boards');
    }
  }
};
</script>
