<template>
  <div
    v-if="isVisible"
    class="ModalView-overlay"
  >
    <div class="ModalView-content">
      <p class="ModalView-title">
        {{ title }}
      </p>
      <p class="ModalView-description">
        {{ description }}
      </p>
      <div class="ModalView-actions">
        <iob-button
          :label="cancelButton"
          type="outlined"
          size="medium"
          color="secondarySoft"
          @click="handleCancelButton"
        />
        <iob-button
          :label="confirmButton"
          size="medium"
          color="alert"
          type="filled"
          @click="handleConfirmButton"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    cancelButton: {
      type: String,
      default: ''
    },
    confirmButton: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleCancelButton() {
      this.$emit('cancelAction');
    },
    handleConfirmButton() {
      this.$emit('confirmAction', this.confirmButton);
    }
  }
};
</script>

<style lang="scss" scoped src="./ModalView.scss" />

