<template>
  <div class="User-container">
    <IobAvatarWithName
      v-if="displayUnassigned || userAvatar.name"
      size="default"
      :name="userAvatar.name"
      :subtitle="userAvatar.roles"
      :alt-text="userAvatar.initials"
      :picture="userAvatar.img"
      color="#0346B5"
      :color-left="false"
      :deleted-user="deletedUser"
    />
  </div>
</template>

<script setup>
import {useUsersStore} from 'SRC/piniaStore/users/users';
import {computed, ref} from 'vue';
import {UNASSIGNED} from 'GLOBALS/constants';

const props = defineProps({
  value: {
    type: String,
    required: true
  },
  displayUnassigned: {
    type: Boolean,
    default: false
  }
});

const usersStore = useUsersStore();
const users = computed(() => usersStore.users);
const deletedUser = ref(false);
const isValidEmail = (user, value) => user.email && (user.email === value || user.email.split('@')[0] === value);
const isValidUserName = (user, value) => user.username && (user.username === value || user.username.split('@')[0] === value);
const getUser = (value) => {
  const user = users.value.find((user) => isValidEmail(user, value) || isValidUserName(user, value));

  if (!user) {
    deletedUser.value = true;
    return { name: props.displayUnassigned ? UNASSIGNED : '', img: '', initials: '', roles: '' };
  }

  const { firstname, lastname, picture, roles } = user;
  return {
    name: `${firstname} ${lastname}`,
    img: picture,
    initials: `${firstname[0]}${lastname[0]}`,
    roles
  };
};
const userAvatar = computed(() => getUser(props.value));
</script>
