import {API_URL} from 'SRC/config/api.js';
import {useAppStore} from 'SRC/piniaStore/app/app';
import CircuitBreaker from 'opossum';
import {makeRequest} from '../utils/CircuitBreaker';
import {CONTENT_TYPES} from 'GLOBALS/constants';
import {vmsApiClient} from '@iobeya/common-tools';

function transformBody(body, transformBodyFn) {
  return body !== null && body !== undefined ? transformBodyFn(body) : null;
}

async function handleResponse(response, toJson) {
  if (response.status === 204) {
    return true;
  }

  const contentType = response?.headers?.get('content-type');

  if (contentType?.includes(CONTENT_TYPES.EXCEL)) {
    return new Blob([response.data], {type: CONTENT_TYPES.EXCEL});
  }

  if (toJson) {
    return response.data;
  }
  return true;
}

function fetcher(method) {
  // eslint-disable-next-line no-unused-vars
  return async (url, body = null, toJson = true, isRelative = true,
    interrupts = false, transformBodyFn = JSON.stringify,
    contentType = CONTENT_TYPES.JSON) => {
    const requestUrl = isRelative ? `${API_URL}${url}` : `${url}`;
    try {
      const response = await vmsApiClient({
        method,
        url: requestUrl,
        headers: {
          ...(contentType !== CONTENT_TYPES.MULTIPART_FORM_DATA && { 'Content-Type': contentType })
        },
        data: transformBody(body, transformBodyFn)
      });

      return await handleResponse(response, toJson);
    } catch (error) {
      const appStore = useAppStore();
      const exception = error?.error || error?.status >= 400 ?
        new Exception(error.status, error.error, null, error.path) : new Exception(error?.status, null, 'Something went wrong');

      if (interrupts) {
        appStore.exceptions.push(exception);
        appStore.setExceptions(appStore.exceptions);
        throw exception;
      }

      throw exception;
    }
  };
}

class Exception {
  constructor(status, type, title, detail) {
    this.status = status;
    this.type = type;
    this.title = title;
    this.detail = detail;
  }
}

const userOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 10000
};
const vmsOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 10000
};
export const vmsCircuitBreaker = new CircuitBreaker(fetcher('GET'), vmsOptions);
export const userCircuitBreaker = new CircuitBreaker(fetcher('GET'), userOptions);
export const get = async (url, body, toJson, isRelative, circuitBreakerInfo = {}) => {
  const {isActivated = true, interrupts, isUsersAPI} = circuitBreakerInfo;
  if (!isActivated) {
    return fetcher('GET')(url, body, toJson, isRelative, {});
  }
  if (isUsersAPI) {
    return makeRequest(userCircuitBreaker, url, body, toJson, isRelative, interrupts);
  }
  return makeRequest(vmsCircuitBreaker, url, body, toJson, isRelative, interrupts);
};

export const patch = fetcher('PATCH');
export const post = fetcher('POST');
export const put = fetcher('PUT');
export const del = fetcher('DELETE');

export default {
  get,
  patch,
  post,
  put,
  del
};
