<template>
  <div class="SummaryCard">
    <IconLoader
      size="l"
      class="SummaryCard-icon"
      :name="icon"
      :color="color"
    />
    <span class="SummaryCard-summary">
      {{ $t(`hierarchy.modal.import-xls.${type}`, [count]) }}
    </span>
  </div>
</template>

<script setup>
defineProps({
  type: {
    type: String,
    required: true
  },
  count: {
    type: Number,
    required: true
  },
  icon: {
    type: String,
    required: true
  },
  color: {
    type: String,
    required: true
  }
});
</script>

<style scoped lang="scss">
.SummaryCard {
  font-size: 12px;
  color: #242230;
  border: 1px solid #E8E7EC;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  text-align: center;
}

.SummaryCard-icon {
  margin-top: 24px;
}

.SummaryCard-summary {
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
}
</style>
