<template>
  <div class="RightPanel-level-detail-item">
    <span class="RightPanel-level-detail-item-label">{{ label }}</span>
    <span class="RightPanel-level-detail-item-value">
      <UserAvatar
        v-if="isUser"
        :value="value"
        :display-unassigned="true"
      />
      <template v-else>
        <span>{{ value }}</span>
      </template>
    </span>
  </div>
</template>

<script setup>
import UserAvatar from 'SRC/views/UserAvatar/UserAvatar.vue';

defineProps({
  label: {
    type: String,
    required: true
  },
  value: {
    type: String,
    required: true
  },
  isUser: {
    type: Boolean,
    default: false
  }
});
</script>

<style lang="scss" scoped src="./LevelDetailItem.scss" />
