<template>
  <div
    v-if="selectedNode"
    class="RightPanel-right-panel"
  >
    <div class="RightPanel-header">
      <p class="RightPanel-level-detail-title">
        Level details
      </p>
      <IconLoader
        name="X"
        color="gray"
        class="RightPanel-close-icon"
        @click="closeRightPanel"
      />
    </div>
    <div class="RightPanel-content">
      <LevelDetailItem
        v-for="(detail, index) in levelDetails"
        :key="index"
        :label="detail.label"
        :value="detail.value"
        :is-user="detail.isUser"
      />
      <div
        class="RightPanel-level-detail"
      >
        <span class="RightPanel-level-detail-label">Members</span>
        <span
          class="RightPanel-level-detail-value"
        >
          <ul
            v-if="selectedNode?.members?.length"
            class="RightPanel-members-list"
          >
            <li
              v-for="(member, index) in limitedMembers"
              :key="index"
              class="RightPanel-member-item"
            >
              <UserAvatar :value="member" />
            </li>
          </ul>
          <button
            v-if="showToggleButton"
            class="RightPanel-level-detail-value-toggle-button"
            @click="toggleShowAllMembers"
          >
            {{ toggleButtonText }}
            <icon-loader
              :name="showAll ? 'ChevronUp' : 'ChevronDown'"
              color="gray"
              class="RightPanel-level-detail-value-toggle-button-icon"
            />
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import LevelDetailItem from 'SRC/views/LevelDetailItem/LevelDetailItem.vue';
import UserAvatar from 'SRC/views/UserAvatar/UserAvatar.vue';

export default {
  components: {
    LevelDetailItem,
    UserAvatar
  },
  props: {
    selectedNode: {
      type: Object,
      required: true
    },
    closeRightPanel: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showAll: false
    };
  },
  computed: {
    levelDetails() {
      const { 'level-name': levelName, 'level-id': levelId, 'organisational-structure': structure, geolocalisation, owner } = this.selectedNode;
      return [
        { label: 'Level name', value: levelName },
        { label: 'ID', value: levelId },
        { label: 'Structure', value: structure },
        { label: 'Geolocation', value: geolocalisation },
        { label: 'Owner', value: owner, isUser: true }
      ];
    },
    limitedMembers() {
      if (!this.selectedNode?.members?.length) {
        return [];
      }
      return this.showAll ? this.selectedNode.members : this.selectedNode.members.slice(0, 5);
    },
    showToggleButton() {
      return this.selectedNode?.members?.length > 5;
    },
    toggleButtonText() {
      const restMembersCount = this.selectedNode.members.length - 5;
      const moreMembers = restMembersCount > 1 ? 'users' : 'user';
      return this.showAll ? 'View less' : `View ${restMembersCount} more ${moreMembers}`;
    }
  },
  methods: {
    toggleShowAllMembers() {
      this.showAll = !this.showAll;
    }
  }
};
</script>

<style lang="scss" scoped src="./LevelDetails.scss" />
