<template>
  <div class="IobComboboxMultiSelect focus-within">
    <iob-combobox
      :type="type"
      :size="size"
      :status="status"
      :dropdown-items="dropdownItems"
      :input-value="inputValue"
      :dropdown-style="dropdownStyle"
      :is-scrollable="true"
      :left-icon="leftIcon"
      :show-left-icon="leftIcon.iconName ? true : false"
      @onClickItem="handleOnClickItem"
      @click="toggleMenu"
    >
      <div
        v-if="displayedItems && displayedItems.length"
        class="IobComboboxMultiSelect-wrapper"
      >
        <iob-badge
          v-for="(item, index) in displayedItems"
          :key="index"
          :label="item.title || item.text"
          :size="item.sizeBadge"
          :color="item.colorBadge"
          :type="item.typeBadge"
          show-close-button
          :rounded="props.rounded"
          @close="$emit('onDeleteBadge', item)"
        />
        <IobHiddenList
          v-if="hiddenselectedItems && hiddenselectedItems.length"
          :hidden-items="hiddenselectedItems"
        />
      </div>
      <iob-label-field
        class="IobComboboxMultiSelect-input"
        :placeholder="placeholder"
        container-styles="border:none; outline:none; background: transparent; padding: 0px"
        @update:modelValue="(value) => (inputValue = value)"
      />
    </iob-combobox>
    <outside-click-listener
      v-if="dropdownOpened"
      @outside-click="hideMenu"
    >
      <iob-dropdown
        v-if="filteredDataForDropdownMenu && filteredDataForDropdownMenu.length"
        class="IobComboboxMultiSelect-dropdown"
        :items="filteredDataForDropdownMenu"
        :is-scrollable="isScrollable"
        :dropdown-style="dropdownStyle"
        @DropdownElementItem="onClickDropdownElement"
        @click.stop
      >
        <span
          v-if="
            filteredDataForDropdownMenu &&
              !filteredDataForDropdownMenu.length &&
              inputValue
          "
          class="IobComboboxMultiSelect-alternativeText"
        >
          {{ alternativeText }}
        </span>
      </iob-dropdown>
    </outside-click-listener>
    <slot />
  </div>
</template>

<script setup>
import IobCombobox from '../IobCombobox/IobCombobox.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import IobHiddenList from '../IobHiddenList/IobHiddenList.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import { defineProps, computed, ref } from 'vue';
import IobLabelField from '../IobLabelField/IobLabelField.vue';

const props = defineProps({
  placeholder: {
    type: String,
    default: ''
  },
  isScrollable: {
    type: Boolean,
    default: false
  },
  leftIcon: {
    type: Object,
    default: () => ({
      iconName: '',
      type: '',
      size: ''
    })
  },
  type: {
    type: String,
    default: 'outlined',
  },
  size: {
    type: String,
    default: 'meduim',
  },
  status: {
    type: String,
    default: 'default'
  },
  rounded: {
    type: Boolean,
    default: false
  },
  dropdownItems: {
    type: [String, Array],
    default: () => []
  },
  dropdownStyle: {
    type: String,
    default: '',
  },
  maxDisplayedItems: {
    type: Number,
    default: 3,
  },
  selectedItems: {
    type: [String, Array],
    default: () => [],
  },
});
const inputValue = ref('');
const dropdownOpened = ref(false);
const emits = defineEmits(['onClickItem']);

const displayedItems = computed(() => {
  return props.selectedItems.slice(0, props.maxDisplayedItems);
});
const hiddenselectedItems = computed(() => {
  return props.selectedItems.slice(props.maxDisplayedItems);
});
const alternativeText = computed(
  () => `No result found for : ${inputValue.value}`
);
const filteredDataForDropdownMenu = computed(
  () =>
    props.dropdownItems &&
    props.dropdownItems.filter(
      (item) =>
        (item.text &&
          item.text?.toLowerCase().includes(inputValue.value?.toLowerCase()) &&
          !props.selectedItems.some(
            (selectedItem) =>
              selectedItem.text?.toLowerCase() === item.text?.toLowerCase() &&
              selectedItem.name === item.name
          )) ||
        (item.title &&
          item.title?.toLowerCase().includes(inputValue.value?.toLowerCase()) &&
          !props.selectedItems.some(
            (selectedItem) =>
              selectedItem.title?.toLowerCase() === item.title.toLowerCase() &&
              selectedItem.name === item.name
          ))
    )
);

const onClickDropdownElement = ({ item }) => {
  dropdownOpened.value = false;
  emits('onClickItem', item);
  inputValue.value = '';
};
const hideMenu = () => {
  dropdownOpened.value = false;
};
const toggleMenu = (e) => {
  e.stopPropagation();
  dropdownOpened.value = true;
};
const handleOnClickItem = (item) => {
  emits('onClickItem', item);
};
</script>
<style lang="scss" src="./IobComboboxMultiSelect.scss" scoped></style>
