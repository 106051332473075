import {HORIZON_TYPES} from 'GLOBALS/constants';
import {utcDate} from 'SRC/utils/utils';

export const TIME_SERIES_FREQUENCIES = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMESTERLY: 'Semesterly',
  BIANNUALLY: 'Biannually',
  YEARLY: 'Yearly'
};

export const POINT_FREQUENCIES = {
  WEEKLY: {
    EVERY_MONDAY: 'Every Monday',
    EVERY_TUESDAY: 'Every Tuesday',
    EVERY_WEDNESDAY: 'Every Wednesday',
    EVERY_THURSDAY: 'Every Thursday',
    EVERY_FRIDAY: 'Every Friday',
    EVERY_SATURDAY: 'Every Saturday',
    EVERY_SUNDAY: 'Every Sunday'
  },
  MONTHLY: {
    FIRST_DAY: 'First day of the month',
    MID_MONTH: '15th of the month',
    LAST_DAY: 'Last day of the month',
    FIRST_MONDAY: 'First Monday of the month',
    LAST_FRIDAY: 'Last Friday of the month'
  },
  QUARTERLY: {
    FIRST_DAY: 'First day of the quarter',
    LAST_DAY: 'Last day of the quarter',
    FIRST_MONDAY: 'First Monday of the quarter',
    LAST_FRIDAY: 'Last Friday of the quarter'
  },
  BIANNUALLY: {
    FIRST_DAY: 'First day of the semester',
    LAST_DAY: 'Last day of the semester',
    FIRST_MONDAY: 'First Monday of the semester',
    LAST_FRIDAY: 'Last Friday of the semester'
  },
  YEARLY: {
    FIRST_DAY: 'First day of the year',
    LAST_DAY: 'Last day of the year',
    FIRST_MONDAY: 'First Monday of the year',
    LAST_FRIDAY: 'Last Friday of the year'
  }
};

export const standardizeText = (value) => value.trim().toLowerCase();

export const splitFrequency = (frequency) => {
  const match = /^([^:]+):([^:]+)$/.exec(frequency);
  if (!match) {
    return { type: frequency, frequency: '' };
  }
  return { type: match[1], frequency: match[2] };
};

/**
 * Returns a time window (start date and end date) based on the provided horizon object.
 * @param {Object} kpi - Object containing the configuration of the time window.
 * @returns {Object} - Object containing the properties startDate and endDate.
 */
export const getKPIHorizonWindow = (kpi) => {
  const horizon = kpi?.attributes?.horizon ?? {};
  const createdOn = kpi?.createdOn;
  let startDate,  endDate;
  if (horizon.type === HORIZON_TYPES.CUSTOM) {
    startDate = new Date(horizon['start-date']) ?? new Date(createdOn);
    endDate = new Date(horizon['end-date']);
  } else if (horizon.type === HORIZON_TYPES.STANDARD) {
    startDate = new Date(createdOn);
    endDate = new Date(Date.UTC(horizon.year, horizon.period, 0));
  }
  return {
    startDate: utcDate(startDate),
    endDate: utcDate(endDate, 23, 59, 59)
  };
};
