<template>
  <IobHeaderNav
    :avatar-url="userAvatar"
    :alt-text="userInitials"
    :show-left="false"
    :show-middle="false"
    :info-btn="false"
    :notification-btn="false"
    :avatar-dropdown-items="[]"
    @avatar-dropdown-element-click="processMultiLevelClick"
  />
</template>

<script>
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { computed } from 'vue';
import i18n from 'SRC/plugins/locales/i18n';
import { useI18n } from 'vue-i18n';
export default {
  name: 'HeaderNav',
  setup() {
    const usersStore = useUsersStore();
    const userInitials = computed(
      () =>
        usersStore.currentUser?.firstname.charAt(0) +
        usersStore.currentUser?.lastname.charAt(0)
    );
    const userAvatar = computed(() => usersStore.currentUser?.picture);

    const { t } = useI18n();
    const currentLocale = computed(() => i18n.global.locale.value);
    const languageList = [
      {
        text: 'English',
        source: 'en'
      },
      {
        text: 'English (United States)',
        source: 'us'
      },
      {
        text: 'Français',
        source: 'fr'
      },
      {
        text: 'Deutsch',
        source: 'de'
      },
      {
        text: 'Español',
        source: 'es'
      },
      {
        text: 'Nederlands',
        source: 'nl'
      },
      {
        text: 'Русский',
        source: 'pl'
      },
      {
        text: '日本語',
        source: ''
      },
      {
        text: '中文',
        source: ''
      }
    ];

    const avatarDropdownLanguageItem = (text, source) => ({
      text,
      iconName: currentLocale.value === source ? 'Check' : '',
      showIcon: true,
      source,
      action: 'changeLanguage'
    });

    const avatarDropdownItems = computed(() => [
      {
        text: t('avatar.profile'),
        action: 'MyProfile',
        iconName: 'User',
        iconSize: 'default',
        type: 'menu',
        showIcon: true
      },
      {
        text: t('avatar.language'),
        state: 'default',
        type: 'menu',
        showIcon: true,
        iconName: 'Languages',
        subText: languageList.find((language) => language.source === currentLocale.value).text,
        showSubtext: true,
        subItemslist: languageList.map(({ text, source }) => avatarDropdownLanguageItem(text, source))
      },
      {
        text: t('avatar.preferences'),
        iconName: 'Settings',
        type: 'menu',
        showIcon: true,
        subItemslist: [
          {
            text: 'Theme',
            state: 'default',
            iconName: 'Theme'
          }
        ]
      },
      {
        text: t('avatar.logout'),
        action: 'Logout',
        state: 'default',
        iconName: 'LogOut',
        type: 'menu',
        showIcon: true
      }
    ]);

    const methods = { changeLanguage };
    function processMultiLevelClick({ action, source }) {
      methods[action](source);
    }

    function changeLanguage(lang) {
      i18n.global.locale.value = lang;
    }

    return {
      userInitials,
      userAvatar,
      avatarDropdownItems,
      processMultiLevelClick,
      changeLanguage
    };
  }
};
</script>
