<template>
  <div class="IobSelect-container">
    <button
      :class="containerClasses"
      :disabled="disabled"
      @click="onSelectClick"
    >
      <div class="IobSelect-left">
        <iob-icon-shape
          v-if="selectedElement.iconColor && selectedElement.iconName"
          :icon-name="selectedElement.iconName"
          :color="selectedElement.iconColor"
          :size="selectedElement.iconSize"
        />
        <div
          v-else-if="selectedElement.iconName || iconName"
          class="IobSelect-icon"
        >
          <icon-loader
            :name="selectedElement.iconName || iconName"
            :size="iconSize"
            class="icon"
          />
        </div>
        <iob-avatar
          v-if="selectedElement.avatarPicture || selectedElement.altText"
          :picture="selectedElement.avatarPicture"
          :alt-text="selectedElement.altText"
          :color="selectedElement.initialsColor"
          :size="elementSize"
        />
        <iob-badge
          v-if="selectedElement.badgeIcon"
          :color="selectedElement.badgeColor"
          :icon="selectedElement.badgeIcon"
          :size="elementSize"
        />
        <icon-loader
          v-if="selectedElement.iconColorName"
          :name="selectedElement.iconColorName"
          :color="selectedElement.iconColor"
          has-background-color
        />
        <iob-shape-color
          v-if="selectedElement.shapeType || shapeType"
          :type="selectedElement.shapeType || shapeType"
          :color="selectedElement.shapeColor || shapeColor"
        />
        <span :class="textClasses">{{
          selectedElement.text ? selectedElement.text : label
        }}</span>
      </div>
      <icon-loader
        v-if="hasArrow"
        class="arrow icon"
        :name="arrowIcon"
      />
    </button>
    <outside-click-listener
      v-if="toggleDropdown"
      @outside-click="handleClickOutsideDropdown"
    >
      <iob-dropdown
        :items="dropdownItems"
        :is-scrollable="isScrollable"
        class="IobSelect-DropdownColorPicker"
        :dropdown-style="dropdownStyle"
        @DropdownElementItem="handleSelectedElement"
      />
    </outside-click-listener>
    <outside-click-listener
      v-if="toggleDropdownColorPicker && hasDropdownColorPicker"
      class="IobSelect-DropdownColorPicker"
      @outside-click="handleClickOutsideDropdownColorPicker"
    >
      <iob-dropdown-color-picker
        :card-type="elementType"
        @select-color="handleSelectedColor"
      />
    </outside-click-listener>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onUpdated } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobAvatar from '../../Molecules/IobAvatar/IobAvatar.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import IobShapeColor from '../../Atoms/IobShapeColor/IobShapeColor.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import IobDropdownColorPicker from '../../Molecules/IobDropdownColorPicker/IobDropdownColorPicker.vue';
import { dataTypesIcons } from '../../../constants';
import IobIconShape from '../../Atoms/IobIconShape/IobIconShape.vue';

const props = defineProps({
  isDataTypesItems: {
    type: Boolean,
    default: false
  },
  isScrollable: {
    type: Boolean,
    default: false
  },
  elementType: { type: String, default: '' },
  modelValue: { type: Object, default: () => ({}) },
  color: { type: String, default: 'secondary' },
  type: { type: String, default: 'filled' },
  size: { type: String, default: 'medium' },
  disabled: { type: Boolean, default: false },
  altText: { type: String, default: '' },
  initialsColor: { type: String, default: '' },
  iconName: { type: String, default: '' },
  avatarPicture: { type: String, default: '' },
  label: { type: String, default: '' },
  badgeColor: { type: String, default: '' },
  badgeIcon: { type: String, default: '' },
  iconColorName: { type: String, default: '' },
  iconColor: { type: String, default: '' },
  shapeType: { type: String, default: '' },
  shapeColor: { type: String, default: '' },
  items: { type: Array, required: true },
  hasArrow: { type: Boolean, default: true },
  hasDropdownColorPicker: { type: Boolean, default: false },
  dropdownStyle: {
    type: String,
    default: 'position: absolute; top: 100% left: 0; width: inherit; z-index: 100;'
  }
});

const emits = defineEmits(['update:modelValue', 'selectColor']);

const toggleDropdown = ref(false);
const iconSize = ref('default');
const elementSize = ref('small');
const toggleDropdownColorPicker = ref(false);
const selectedElement = ref(props.modelValue);
const currentIconName = ref(props.iconName);

const secondaryColorMap = {
  filled: 'IobSelect-secondary-filled',
  outlined: 'IobSelect-secondary-outlined',
  ghost: 'IobSelect-secondary-ghost',
};

const secondarySoftColorMap = {
  filled: 'IobSelect-secondary-soft-filled',
  outlined: 'IobSelect-secondary-soft-outlined',
  ghost: 'IobSelect-secondary-soft-ghost',
};

const sizeMap = {
  default: 'IobSelect-default',
  medium: 'IobSelect-medium',
  large: 'IobSelect-large',
};

const textClassMap = {
  false: 'text',
  true: 'disabled-text',
};

const textPaddingClassMap = {
  default: 'text-default',
  medium: 'text-medium',
  large: 'text-large',
};

const containerClasses = computed(() => {
  if (props.color === 'secondary') {
    return `IobSelect ${secondaryColorMap[props.type]}${
      props.disabled ? '-disabled' : ''
    } ${sizeMap[props.size]} focus-outside`;
  } else {
    return `IobSelect ${secondarySoftColorMap[props.type]}${
      props.disabled ? '-disabled' : ''
    } ${sizeMap[props.size]} focus-outside`;
  }
});

const textClasses = computed(() => {
  if (props.color === 'secondary') {
    return `${secondaryColorMap[props.type]}-${textClassMap[props.disabled]} ${
      secondaryColorMap[props.type]
    }-${textPaddingClassMap[props.size]}`;
  } else {
    return `${secondarySoftColorMap[props.type]}-${
      textClassMap[props.disabled]
    } ${secondarySoftColorMap[props.type]}-${textPaddingClassMap[props.size]}`;
  }
});
const arrowIcon = computed(() =>
  toggleDropdown.value || toggleDropdownColorPicker.value ? 'ChevronUp' : 'ChevronDown'
);

const dropdownItems = computed(() => {
  if (props.isDataTypesItems)
    return Object.keys(dataTypesIcons).map((item) => {
      return {
        type: 'icon-shape',
        title: item,
        text: item,
        iconSize: 'xsmall',
        isClickable: true,
        iconName: dataTypesIcons[item]?.icons.parent,
        iconColor: dataTypesIcons[item]?.color
      };
    });
  return props.items.map((item) => {
    if (item.type === 'button') {
      return {
        ...item,
        selected: isSelected(item)
      };
    }
    return { ...item };
  });
});

const onSelectClick = () => {
  if (props.hasDropdownColorPicker) {
    toggleDropdownColorPicker.value = !toggleDropdownColorPicker.value;
  } else {
    toggleDropdown.value = !toggleDropdown.value;
  }
};

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};

const isSelected = (item) => {
  return item.iconName === currentIconName.value;
};

const handleSelectedElement = ({ item }) => {
  selectedElement.value = item;
  toggleDropdown.value = false;
  emits('update:modelValue', selectedElement.value);
  currentIconName.value = selectedElement.value.iconName;
};

const setElementSize = () => {
  if (props.size === 'default') {
    iconSize.value = props.size;
    elementSize.value = 'small';
  } else if (props.size === 'large') {
    iconSize.value = props.size;
    elementSize.value = 'default';
  } else {
    iconSize.value = 'default';
    elementSize.value = 'xsmall';
  }
};

const handleClickOutsideDropdownColorPicker = () => {
  toggleDropdownColorPicker.value = false;
};

const handleSelectedColor = (selectedColor) => {
  emits('selectColor', selectedColor);
  toggleDropdownColorPicker.value = false;
};
onUpdated(() => {
  setElementSize();
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-select.scss" />
<style lang="scss" scoped src="./IobSelect.scss" />
