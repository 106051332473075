<template>
  <div>
    <IobHeaderAction
      :title="$t('hierarchy.label')"
      :sub-title="hierarchiesCount"
      button-icon="CloudUpload"
      :filter-button="false"
      :sort-button="false"
      :list-view-button="false"
      :has-search-input="false"
      :more-btn="true"
      @more-menu="toggleDropdown"
      @button-clicked="showUploadModal"
    />
    <input
      ref="fileInput"
      type="file"
      hidden
      :accept="EXCEL_FILE_EXTENSIONS"
      @change="handleFileUpload"
    >
    <IobAlert
      v-if="showAlert"
      class="floating-alert"
      v-bind="showAlertInfo"
      @close="hideAlert"
    />
    <div
      v-show="!isInProgress"
      id="svg-tree"
      :key="lastHierarchyUpdatedDate"
    />
    <LoadingPageLayer
      v-if="isInProgress"
      size="extraLarge"
      spinner-color="blue"
    />
    <LevelDetails
      v-if="selectedNode"
      :selected-node="selectedNode"
      :close-right-panel="closeRightPanel"
    />
    <MultiLevelMenu
      v-if="isDropdownVisible"
      :items="menuDataItems"
      class="multiple-level-menu"
      sub-menu-position="left"
      @click="handleClickAction"
    />
    <ModalView
      :is-visible="isModalVisible"
      :title="modalInfo.title"
      :description="modalInfo.description"
      :cancel-button="modalInfo.cancelButton"
      :confirm-button="modalInfo.confirmButton"
      @cancel-action="hideModal"
      @confirm-action="handleConfirmation"
    />
    <IobModal
      :show-modal="isUpdateImportResultModalOpen"
      class="UpdateImportResult-modal"
      size="medium"
      :title="importResult.success ? t('hierarchy.modal.import-xls.update') : t('hierarchy.modal.import-xls.update-fail')"
      popup-sidebar="left"
      :should-close-on-outside-click="false"
      @close="closeUpdateImportResultModal"
    >
      <template #popupContent>
        <div class="UpdateImportResult-modal-content">
          <span class="UpdateImportResult-modal-content-subtitle">
            {{ $t('hierarchy.modal.import-xls.summary-title') }}
          </span>
          <div
            class="UpdateImportResult-modal-content-cardsGrid grid gap-x-4"
            :class="`grid-cols-${filteredSummaryDetail.length}`"
          >
            <SummaryCard
              v-for="item in filteredSummaryDetail"
              :key="item.type"
              :type="item.type"
              :count="item.count"
              :icon="item.icon"
              :color="item.color"
            />
          </div>
          <IobSeparator />
          <div class="UpdateImportResult-modal-content-summaryDetails flex flex-col gap-4">
            <SummaryDetailRow
              :title="summaryDetail[FILENAME_SUMMARY_DETAIL_INDEX].title"
              :info="summaryDetail[FILENAME_SUMMARY_DETAIL_INDEX].info"
            />
            <SummaryDetailRow
              v-for="detail in filteredSummaryDetail"
              :key="detail.type"
              :title="detail.title"
              :info="detail.info"
              :alert-info="detail.alertInfo"
            />
          </div>
        </div>
      </template>

      <template #popupFooter>
        <iob-action-footer
          :action-btn-label="t('hierarchy.modal.import-xls.button-confirmation')"
          :right-action-btn-label="importResult.success ? t('hierarchy.popup.modify.buttonCancel') : t('hierarchy.modal.import-xls.button-close')"
          :is-primary-btn-visible="importResult.success"
          :is-primary-btn-disabled="disableUpdateButton"
          :has-spinner="disableUpdateButton"
          :show-secondary-btn="!disableUpdateButton"
          @primary-click="updateHierarchy"
          @secondary-click="closeUpdateImportResultModal"
        />
      </template>

      <template #popupSidebarContent>
        <div class="UpdateImportResult-modal-sidebar">
          <img
            :src="importResult.success ? importSummarySuccessImage : importSummaryFailureImage"
            alt="Update Import Result"
          >
        </div>
      </template>
    </IobModal>
  </div>
</template>

<script setup>
import {useRouter} from 'vue-router';
import {computed, createApp, h, nextTick, ref, watch} from 'vue';
import ApexTree from 'apextree';
import LevelDetails from '../LevelDetails/LevelDetails.vue';
import TreeNodeTemplate from '../ApexNodeTemplate/TreeNodeTemplate.vue';
import {useAppStore} from 'SRC/piniaStore/app/app';
import {ALTER_TYPES, DATA_TYPES_NAMES, EXCEL_FILE_EXTENSIONS, FEATURES, HIERARCHY} from 'SRC/globals/constants';
import ModalView from '../ModalView/ModalView.vue';
import utils from '../utils';
import templateHierarchy from 'SRC/assets/templates/Template-hierarchy.xlsx';
import {useI18n} from 'vue-i18n';
import SummaryCard from '@/views/SummaryCard/SummaryCard.vue';
import SummaryDetailRow from '@/views/SummaryDetailRow/SummaryDetailRow.vue';
import importSummarySuccessImage from 'SRC/assets/illustrations/hierarchy-import-summary-success.svg';
import importSummaryFailureImage from 'SRC/assets/illustrations/hierarchy-import-summary-failure.svg';
import {isFeatureEnabled} from '@/utils/utils';

const routeChanged = ref(false);
const hierarchiesCount = ref(1);
const selectedNode = ref(null);
const isDropdownVisible = ref(false);
const isModalVisible = ref(false);
const modalInfo = ref({});
const fileInput = ref(null);
const isInProgress = ref(false);
const showAlert = ref(false);
const showAlertInfo = ref({});
const uploadMode = ref(HIERARCHY.UPLOAD_MODES.NEW_HIERARCHY);
const isUpdateImportResultModalOpen = ref(false);
const disableUpdateButton = ref(false);
const importResult = ref({
  success: false,
  filename: '',
  deleteElements: [],
  addElements: [],
  updateElements: [],
  duplicateElements: [],
  elementsWithoutParent: [],
  elementsWithMissingFields: []
});
const lastHierarchyUpdatedDate = ref(Date.now());

const { t } = useI18n();
const router = useRouter();
const appStore = useAppStore();
const levels = computed(() => appStore.levels);
const levelsTree = computed(() => appStore.levelsTree);
const levelDataTypeId = computed(() => getLevelDataTypeId());
const featureToggles = computed(() => appStore.featureToggles);
const canRemoveHierarchy = computed(() => isFeatureEnabled(featureToggles.value, FEATURES.REMOVE_HIERARCHY_FEATURE));
const menuDataItems = computed(() => canRemoveHierarchy.value ? menuData : menuData.filter((item) => item.id !== 4));

const closeUpdateImportResultModal = () => isUpdateImportResultModalOpen.value = false;
const showUpdateImportResultModal = () => isUpdateImportResultModalOpen.value = true;
const updateHierarchy = async () => {
  disableUpdateButton.value = true;
  await appStore.updateExistingHierarchy(updateHierarchyRequest.value);
  closeUpdateImportResultModal();
  disableUpdateButton.value = false;
  updateAlertInfo(ALTER_TYPES.SUCCESS, t('hierarchy.alert.import-xls-success'));
};

const getLevelDataTypeId = () => {
  const levelDataType = appStore.getSystemDataTypeByName(DATA_TYPES_NAMES.LEVEL);
  return levelDataType?.id ?? '';
};

const toggleDropdown = () => {
  isDropdownVisible.value = !isDropdownVisible.value;
};

const closeRightPanel = () => {
  selectedNode.value = null;
};

const showModal = () => {
  isDropdownVisible.value = false;
  isModalVisible.value = true;
};

const showDeletionModal = () => {
  modalInfo.value = {
    title: t('hierarchy.popup.remove.title'),
    description: t('hierarchy.popup.remove.description'),
    cancelButton: t('hierarchy.popup.remove.buttonCancel'),
    confirmButton: t('hierarchy.popup.remove.buttonConfirmation')
  };
  showModal();
};

const showUploadModal = () => {
  modalInfo.value = {
    title: t('hierarchy.popup.modify.title'),
    description: t('hierarchy.popup.modify.description'),
    cancelButton: t('hierarchy.popup.modify.buttonCancel'),
    confirmButton: t('hierarchy.popup.modify.buttonConfirmation')
  };
  uploadMode.value = HIERARCHY.UPLOAD_MODES.UPDATE_HIERARCHY;
  showModal();
};

const hideModal = () => {
  isModalVisible.value = false;
};

const hideAlert = () => {
  showAlert.value = false;
};

const updateAlertInfo = (type = ALTER_TYPES.ERROR, title = '', message = '') => {
  showAlert.value = true;
  showAlertInfo.value = {
    title,
    type,
    message,
    showClose: true,
    showIcon: true
  };
  setTimeout(() => {
    hideAlert();
  }, 5000);
};

const confirmRemove = async () => {
  try {
    const elementsIds = Object.keys(levels.value);
    await appStore.deleteHierarchy(elementsIds);
    hideModal();
    // TODO: Integrate the import component with this component and manage the display using v-if instead of the router
    router.push({ path: '/hierarchy-import' });
  } catch (error) {
    hideModal();
    updateAlertInfo(ALTER_TYPES.ERROR, t('hierarchy.remove.fail'));
    console.error('Failed to delete hierarchy:', error);
  }
};

const treeNodeOptions = () => ({
  ...utils.getApexTreeOptions(),
  nodeTemplate: (content) => {
    const nodeTemplateWrapper = document.createElement('div');
    const app = createApp({
      render() {
        return h(TreeNodeTemplate, { content });
      }
    });
    app.mount(nodeTemplateWrapper);
    return nodeTemplateWrapper.innerHTML;
  }
});

const initializeTree = async () => {
  const level = levelsTree.value?.length ? levelsTree.value[0] : undefined;
  if (level) {
    lastHierarchyUpdatedDate.value = Date.now();
    isInProgress.value = false;
    await nextTick();
    const treeElement = document.getElementById('svg-tree');
    const tree = new ApexTree(treeElement, treeNodeOptions());
    tree.render(level);
    attachClickEvent(treeElement);
  } else {
    router.push({ path: '/hierarchy-import' });
  }
};

const attachClickEvent = (element) => {
  element.addEventListener('click', (event) => {
    const clickedNode = event.target.closest('.apex-tree-node');
    if (clickedNode) {
      const nodeId = clickedNode.id;
      const nodeData = Object.values(levels.value).find((element) => element.attributes['level-id'] === nodeId);
      if (nodeData) {
        selectedNode.value = nodeData.attributes;
      }
    } else {
      closeRightPanel();
    }
  });
};

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    await uploadFile(file);
    event.target.value = '';
  }
};

const uploadFile = async (file) => {
  isInProgress.value = true;
  const formData = new FormData();
  formData.append('file', file, 'TemplateHierarchy.xlsx');
  formData.append('dataTypeId', levelDataTypeId.value);
  const transformBodyFn = (body) => body;

  const url = uploadMode.value === HIERARCHY.UPLOAD_MODES.UPDATE_HIERARCHY ? '/check' : '';
  const result = await appStore.ImportExcelFile(formData, transformBodyFn, url, uploadMode.value);

  if (result.error) {
    updateAlertInfo(ALTER_TYPES.ERROR, t('hierarchy.modal.import-xls.update-fail'));
  } else if (uploadMode.value === HIERARCHY.UPLOAD_MODES.UPDATE_HIERARCHY) {
    const data = result.data[0];
    importResult.value = {
      ...data,
      success: result.success
        && data.duplicateElements.length === 0
        && data.elementsWithoutParent.length < 2
        && data.elementsWithMissingFields.length === 0,
      filename: file.name
    };
    updateHierarchyRequest.value = data;
    showUpdateImportResultModal();
  }
  isInProgress.value = false;
};

const handleConfirmation = async (eventMessage) => {
  if (eventMessage === t('hierarchy.popup.remove.buttonConfirmation')) {
    await confirmRemove();
  } else if (eventMessage === t('hierarchy.popup.modify.buttonConfirmation')) {
    fileInput.value.click();
  }
  hideModal();
};

const filteredSummaryDetail = computed(() =>
  summaryDetail.value.filter((detail) =>
    (detail.type !== 'file-name')
    && importResult.value.success === detail.isInSuccess
  )
);

watch(() => levelsTree.value,
  async () => {
    await initializeTree();
  }, { deep: true }
);

router.beforeEach((to, from, next) => {
  if (to.path === HIERARCHY.VIEW_PATH) {
    routeChanged.value = !routeChanged.value;
  }
  next();
});

watch(() => routeChanged.value,
  async () => {
    if (routeChanged.value) {
      await initializeTree();
      routeChanged.value = false;
    }
  }, { deep: true }
);

watch(() => importResult.value, () => {
  const mapLevelIds = (elements) => elements.reduce((acc, el) => {
    const levelId = el.attributes?.['level-id'] ?? '';
    if (levelId) {
      acc.push(levelId);
    }
    return acc;
  }, []).join('; ');

  summaryDetail.value.forEach((detail) => {
    switch (detail.type) {
    case 'level-created': {
      const addedLevelsCount = importResult.value.addElements.length;
      detail.info = addedLevelsCount > 0 ?
        mapLevelIds(importResult.value.addElements) : t('hierarchy.modal.import-xls.resume.no-result');
      detail.count = addedLevelsCount;
      break;
    }
    case 'level-updated': {
      const updatedLevelsCount = importResult.value.updateElements.length;
      detail.info = updatedLevelsCount > 0 ?
        t('hierarchy.modal.import-xls.resume.level-updated-description') : t('hierarchy.modal.import-xls.resume.no-result');
      detail.count = updatedLevelsCount;
      break;
    }
    case 'level-deleted': {
      const deletedElements = importResult.value.deleteElements.map((id) => levels.value[id]);
      const deletedLevelsCount = importResult.value.deleteElements.length;
      if (deletedLevelsCount > 0) {
        detail.alertInfo = t('hierarchy.modal.import-xls.resume.level-deleted-description');
        detail.info = mapLevelIds(deletedElements);
      } else {
        detail.info = t('hierarchy.modal.import-xls.resume.no-result');
        detail.alertInfo = '';
      }
      detail.count = deletedLevelsCount;
      break;
    }
    case 'level-duplicates':
      detail.info = importResult.value.duplicateElements.join('; ');
      detail.count = importResult.value.duplicateElements.length;
      break;
    case 'level-multiple-root':
      if (importResult.value.elementsWithoutParent.length > 1) {
        detail.info = importResult.value.elementsWithoutParent.join('; ');
        detail.count = importResult.value.elementsWithoutParent.length;
      }
      break;
    case 'level-field-missing':
      detail.info = importResult.value.elementsWithMissingFields.map((element) => {
        const [key, value] = Object.entries(element)[0];
        return t('hierarchy.modal.import-xls.resume.level-field-missing-info', [key, value]);
      }).join(', ');
      detail.count = importResult.value.elementsWithMissingFields.length;
      break;
    case 'file-name':
      detail.info = importResult.value.filename;
      break;
    }
  });
});

const menuData = [
  {
    id: 1,
    title: t('hierarchy.contextualMenu.download.download'),
    iconName: 'Download',
    submenu: {
      0: {
        id: 2,
        title: t('hierarchy.contextualMenu.download.template'),
        action: 'exportTemplate'
      },
      1: {
        id: 3,
        title: t('hierarchy.contextualMenu.download.currentHierarchy'),
        action: 'exportHierarchy'
      }
    }
  },
  {
    id: 4,
    title: t('hierarchy.contextualMenu.remove'),
    action: 'removeHierarchy',
    iconName: 'Trash'
  }
];

const excelFileTypes = 'vnd.ms-excel';
const actions = {
  exportTemplate: async () => {
    await appStore.exportFile(templateHierarchy, 'Template-hierarchy.xlsx', excelFileTypes, true);
  },
  exportHierarchy: async () => {
    const exportTemplateUrl = `/dataset-elements/export?dataTypeId=${levelDataTypeId.value}`;
    await appStore.exportFile(exportTemplateUrl, 'Hierarchy.xlsx', excelFileTypes);
  },
  removeHierarchy: () => {
    showDeletionModal();
  }
};

const FILENAME_SUMMARY_DETAIL_INDEX = 0;
const summaryDetail = ref([
  {
    title: t('hierarchy.modal.import-xls.resume.name-file-title'),
    info: '',
    alertInfo: '',
    type: 'file-name',
    isInSuccess: false
  },
  {
    title: t('hierarchy.modal.import-xls.resume.level-created-title'),
    elements: importResult.value.addElements,
    count: 0,
    alertInfo: '',
    type: 'level-created',
    icon: 'PlusCircle',
    color: '#47435F',
    isInSuccess: true
  },
  {
    title: t('hierarchy.modal.import-xls.resume.level-updated-title'),
    elements: importResult.value.updateElements,
    info: t('hierarchy.modal.import-xls.resume.level-updated-description'),
    alertInfo: '',
    count: 0,
    type: 'level-updated',
    icon: 'CheckCircle-2',
    color: '#47435F',
    isInSuccess: true
  },
  {
    title: t('hierarchy.modal.import-xls.resume.level-deleted-title'),
    info: '',
    alertInfo: t('hierarchy.modal.import-xls.resume.level-deleted-description'),
    count: 0,
    type: 'level-deleted',
    icon: 'XCircle',
    color: '#D34343',
    isInSuccess: true
  },
  {
    title: t('hierarchy.modal.import-xls.resume.level-duplicate-title'),
    info: '',
    alertInfo: '',
    count: 0,
    type: 'level-duplicates',
    icon: 'CopyPlus',
    color: '#D34343',
    isInSuccess: false
  },
  {
    title: t('hierarchy.modal.import-xls.resume.level-multiple-root-title'),
    info: '',
    alertInfo: '',
    count: 0,
    type: 'level-multiple-root',
    icon: 'FolderTree',
    color: '#D34343',
    isInSuccess: false
  },
  {
    title: t('hierarchy.modal.import-xls.resume.level-field-missing-title'),
    info: '',
    alertInfo: '',
    count: 0,
    type: 'level-field-missing',
    icon: 'AsteriskSquare',
    color: '#D34343',
    isInSuccess: false
  }
]);
const updateHierarchyRequest = ref({
  dataTypeId: '',
  deleteElements: [],
  addElements: [],
  updateElements: []
});

const handleClickAction = async (options) => {
  const action = actions[options.action];
  if (action) {
    await action();
  }
};
</script>

<style lang="scss" src="./HierarchyView.scss" />
