import dayjs from 'dayjs';
import {
  POINT_FREQUENCIES,
  splitFrequency,
  standardizeText,
  TIME_SERIES_FREQUENCIES
} from 'SRC/piniaStore/timeSeries/utils';

class DateGenerator {
  constructor(frequency, startDate, endDate) {
    const {type, frequency: value} = splitFrequency(frequency);
    this.frequencyType = type;
    this.frequency = value;
    this.startDate = dayjs(startDate);
    this.endDate = dayjs(endDate);
  }

  /**
   * Generates a list of dates based on the selected frequency.
   * Iterates from the start date to the end date, adding each matching date
   * to the list based on the frequency specified.
   *
   * @returns {string[]} An array of dates formatted as 'YYYY-MM-DD'.
   */
  generateDates() {
    if (!this.frequencyType || !this.frequency) {
      return [];
    }
    // If the start date is after the end date, set the start date to the end date
    if (this.endDate.isBefore(this.startDate)) {
      this.startDate = this.endDate;
    }

    const handler = this.frequencyHandlers[standardizeText(this.frequencyType)];

    return handler(standardizeText(this.frequency));

  }

  /**
   * Frequency handlers.
   */
  frequencyHandlers = {
    [standardizeText(TIME_SERIES_FREQUENCIES.DAILY)]: () => this.generateDatesByDateResolver(this.resolveDaily),
    [standardizeText(TIME_SERIES_FREQUENCIES.WEEKLY)]: (frequency) => this.generateWeeklyDates(frequency),
    [standardizeText(TIME_SERIES_FREQUENCIES.MONTHLY)]: (frequency) => this.generateMonthlyDates(frequency),
    [standardizeText(TIME_SERIES_FREQUENCIES.QUARTERLY)]: (frequency) => this.generateQuarterlyDates(frequency),
    [standardizeText(TIME_SERIES_FREQUENCIES.BIANNUALLY)]: (frequency) => this.generateBiannuallyDates(frequency),
    [standardizeText(TIME_SERIES_FREQUENCIES.YEARLY)]: (frequency) => this.generateYearlyDates(frequency)
  };

  /**
   * Generate Weekly dates.
   */
  generateWeeklyDates(frequency) {
    const dayMap = {
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_MONDAY)]: 1,
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_TUESDAY)]: 2,
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_WEDNESDAY)]: 3,
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_THURSDAY)]: 4,
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_FRIDAY)]: 5,
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_SATURDAY)]: 6,
      [standardizeText(POINT_FREQUENCIES.WEEKLY.EVERY_SUNDAY)]: 0
    };
    return this.generateDatesByDateResolver(this.resolveSpecificDayOfWeek(dayMap[frequency]));
  }

  /**
   * Generate monthly dates.
   */
  generateMonthlyDates(frequency) {
    switch (frequency) {
    case standardizeText(POINT_FREQUENCIES.MONTHLY.FIRST_DAY):
      return this.generateDatesByDateResolver(this.resolveFirstDay);
    case standardizeText(POINT_FREQUENCIES.MONTHLY.MID_MONTH):
      return this.generateDatesByDateResolver(this.resolveFifteenthDay);
    case standardizeText(POINT_FREQUENCIES.MONTHLY.LAST_DAY):
      return this.generateDatesByDateResolver(this.resolveLastDay);
    case standardizeText(POINT_FREQUENCIES.MONTHLY.FIRST_MONDAY):
      return this.generateDatesByDateResolver(this.resolveFirstMonday);
    case standardizeText(POINT_FREQUENCIES.MONTHLY.LAST_FRIDAY):
      return this.generateDatesByDateResolver(this.resolveLastFriday);
    default:
      return [];
    }
  }

  /**
   * Generate quarterly dates.
   */
  generateQuarterlyDates(frequency) {
    switch (frequency) {
    case standardizeText(POINT_FREQUENCIES.QUARTERLY.FIRST_DAY):
      return this.generateDatesByDateResolver(this.resolveFirstDay);

    case standardizeText(POINT_FREQUENCIES.QUARTERLY.LAST_DAY):
      return this.generateDatesByDateResolver(this.resolveLastDay);

    case standardizeText(POINT_FREQUENCIES.QUARTERLY.FIRST_MONDAY):
      return this.generateDatesByDateResolver(this.resolveFirstMonday);

    case standardizeText(POINT_FREQUENCIES.QUARTERLY.LAST_FRIDAY):
      return this.generateDatesByDateResolver(this.resolveLastFriday);
    default:
      return [];
    }
  }

  /**
   * Generate biannually dates.
   */
  generateBiannuallyDates(frequency) {
    switch (frequency) {
    case standardizeText(POINT_FREQUENCIES.BIANNUALLY.FIRST_DAY):
      return this.generateDatesByDateResolver(this.resolveFirstDay);
    case standardizeText(POINT_FREQUENCIES.BIANNUALLY.LAST_DAY):
      return this.generateDatesByDateResolver(this.resolveLastDay);
    case standardizeText(POINT_FREQUENCIES.BIANNUALLY.FIRST_MONDAY):
      return this.generateDatesByDateResolver(this.resolveFirstMonday);
    case standardizeText(POINT_FREQUENCIES.BIANNUALLY.LAST_FRIDAY):
      return this.generateDatesByDateResolver(this.resolveLastFriday);
    default:
      return [];
    }
  }

  /**
   * Generate yearly dates.
   */
  generateYearlyDates(frequency) {
    switch (frequency) {
    case standardizeText(POINT_FREQUENCIES.YEARLY.FIRST_DAY):
      return this.generateDatesByDateResolver(this.resolveFirstDay);
    case standardizeText(POINT_FREQUENCIES.YEARLY.LAST_DAY):
      return this.generateDatesByDateResolver(this.resolveLastDay);
    case standardizeText(POINT_FREQUENCIES.YEARLY.FIRST_MONDAY):
      return this.generateDatesByDateResolver(this.resolveFirstMonday);
    case standardizeText(POINT_FREQUENCIES.YEARLY.LAST_FRIDAY):
      return this.generateDatesByDateResolver(this.resolveLastFriday);
    default:
      return [];
    }
  }

  /**
   * Calculates specific dates for a given frequency and range.
   * @param {function(dayjs.Dayjs, boolean): dayjs.Dayjs} dateResolver - Function to calculate the target date in the period
   * @returns {string[]} - List of calculated dates (format YYYY-MM-DD)
   */
  generateDatesByDateResolver(dateResolver) {
    const result = [];

    // Validate input dates
    if (!this.startDate.isValid() || !this.endDate.isValid()) {
      throw new Error('Parameters must be valid dates in the format YYYY-MM-DD.');
    }

    const frequencies = {
      [standardizeText(TIME_SERIES_FREQUENCIES.DAILY)]: 1,
      [standardizeText(TIME_SERIES_FREQUENCIES.WEEKLY)]: 1,
      [standardizeText(TIME_SERIES_FREQUENCIES.MONTHLY)]: 1,
      [standardizeText(TIME_SERIES_FREQUENCIES.QUARTERLY)]: 3,
      [standardizeText(TIME_SERIES_FREQUENCIES.BIANNUALLY)]: 6,
      [standardizeText(TIME_SERIES_FREQUENCIES.YEARLY)]: 12
    };

    const step = frequencies[standardizeText(this.frequencyType)];
    if (!step) {
      throw new Error('Frequency must be \'Daily\', \'Weekly\', \'Monthly\', \'Quarterly\', \'Semesterly\', or \'Yearly\'.');
    }

    let currentPeriodStart = this.startDate.startOf('day');
    if (standardizeText(this.frequencyType) === standardizeText(TIME_SERIES_FREQUENCIES.WEEKLY)) {
      currentPeriodStart = this.startDate.startOf('week');
    } else if (standardizeText(this.frequencyType) !== standardizeText(TIME_SERIES_FREQUENCIES.DAILY)) {
      currentPeriodStart = this.startDate.startOf('month');
      currentPeriodStart = currentPeriodStart.month(Math.floor(currentPeriodStart.month() / step) * step).startOf('month');
    }

    const periodEnd = this.geEndPeriod();

    while (currentPeriodStart.isBefore(periodEnd) || currentPeriodStart.isSame(periodEnd, 'day')) {
      const targetDate = dateResolver(currentPeriodStart, step);
      result.push(targetDate.format('YYYY-MM-DD'));
      currentPeriodStart = currentPeriodStart.add(step, this.getUnit());
    }

    return result;
  }

  geEndPeriod() {
    const frequencyEndMapping = {
      [standardizeText(TIME_SERIES_FREQUENCIES.DAILY)]: () => this.endDate.endOf('day'),
      [standardizeText(TIME_SERIES_FREQUENCIES.WEEKLY)]: () => this.endDate.endOf('week'),
      [standardizeText(TIME_SERIES_FREQUENCIES.MONTHLY)]: () => this.endDate.endOf('month'),
      [standardizeText(TIME_SERIES_FREQUENCIES.QUARTERLY)]: () => this.endDate.month(Math.floor(this.endDate.month() / 3) * 3 + 2).endOf('month'),
      [standardizeText(TIME_SERIES_FREQUENCIES.BIANNUALLY)]: () => this.endDate.month(this.endDate.month() < 6 ? 5 : 11).endOf('month'),
      [standardizeText(TIME_SERIES_FREQUENCIES.YEARLY)]: () => this.endDate.endOf('year')
    };

    return frequencyEndMapping[standardizeText(this.frequencyType)]();
  }

  getUnit() {
    return standardizeText(this.frequencyType) === standardizeText(TIME_SERIES_FREQUENCIES.DAILY) ? 'day' :
      (standardizeText(this.frequencyType) === standardizeText(TIME_SERIES_FREQUENCIES.WEEKLY) ? 'week' : 'month');
  }

  /**
   * Returns the last Friday of the period.
   */
  resolveLastFriday(periodStart, step) {
    let lastDay = periodStart.add(step, 'month').subtract(1, 'day').endOf('month');
    while (lastDay.day() !== 5) {
      lastDay = lastDay.subtract(1, 'day');
    }
    return lastDay;
  }

  /**
   * Returns the first Monday of the period.
   */
  resolveFirstMonday(periodStart) {
    let firstDay = periodStart.startOf('month');
    while (firstDay.day() !== 1) {
      firstDay = firstDay.add(1, 'day');
    }
    return firstDay;
  }

  /**
   * Returns the 15th day of the month for Monthly frequency.
   */
  resolveFifteenthDay(periodStart) {
    return periodStart.startOf('month').date(15);
  }

  /**
   * Returns the first day of the period.
   */
  resolveFirstDay(periodStart) {
    return periodStart.startOf('month');
  }

  /**
   * Returns the last day of the period.
   */
  resolveLastDay(periodStart, step) {
    return periodStart.add(step, 'month').subtract(1, 'day').endOf('month');
  }

  /**
   * Returns a specific day of the week (Monday, Tuesday, etc.) for Weekly frequency.
   * @param {number} day - The day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
   */
  resolveSpecificDayOfWeek(day) {
    return function (periodStart) {
      let targetDay = periodStart.startOf('week');
      while (targetDay.day() !== day) {
        targetDay = targetDay.add(1, 'day');
      }
      return targetDay;
    };
  }

  /**
   * Returns every day for Daily frequency.
   */
  resolveDaily(periodStart) {
    return periodStart.startOf('day');
  }
}

export default DateGenerator;
