import {defineStore} from 'pinia';
import {PAGE_SIZE} from 'GLOBALS/constants';
import {utcDate} from 'SRC/utils/utils';
import {get, post, put} from '@/api';
import {useDataStore} from 'SRC/piniaStore/data/data';
import {API_URL} from 'SRC/config/api.js';
import DateGenerator from 'SRC/piniaStore/timeSeries/DateGenerator';
import {getKPIHorizonWindow} from 'SRC/piniaStore/timeSeries/utils';

const initialState = () => ({
  timeSeriesPoints: [],
  valueType: {},
  timeSeriesActualValue: {}
});
export const useTimeSeriesStore = defineStore('timeSeries', {
  state: initialState,
  actions: {
    async fetchTimeSeriesActualValue(timeSeriesId, kpi) {
      if (!timeSeriesId || !kpi) {
        return {};
      }

      const timeWindow  = getKPIHorizonWindow(kpi);
      const frequency = kpi.attributes.timeSeriesFrequency;
      const end = new Date();
      let actualValue;
      const dateGenerator = new DateGenerator(frequency, timeWindow.startDate, end);
      const query = dateGenerator.generateDates().map((id) => `dates=${id}`).join('&');
      if (query) {
        try {
          const response = await get(`/timeseries/${timeSeriesId}?${query}&size=1`);
          const data = response?.data ?? [];
          actualValue = data.length ? data[0] : undefined;
        } catch (error) {
          console.error('Error fetching the actual timeseries value:', error);
          throw error;
        }
      }
      this.setActualValue(timeSeriesId, actualValue);
    },
    async fetchTimeSeries(id, startDate, endDate) {
      if (!id) {
        console.warn('Time series id is required');
        return [];
      }
      startDate = utcDate(startDate);
      endDate = utcDate(endDate, 23, 59, 59, 999);
      const start = startDate ? `start=${startDate.toISOString()}&` : '';
      const end = endDate ? `end=${endDate.toISOString()}&` : '';
      try {
        const dataStore = useDataStore();
        const url = `${API_URL}/timeseries/${id}?${start}${end}page=1&size=${PAGE_SIZE}`;
        const data = dataStore.fetchPagedData(url, true);
        this.timeSeriesPoints = {
          ...this.timeSeriesPoints,
          [id]: data
        };

        return data;
      } catch (error) {
        console.error('Error in fetchTimeSeriesPointsById', error);
      }
    },
    async createTimeSeries(timeSeriesData, kpi) {
      try {
        const url = '/timeseries';
        const createdTimeSeries = await post(url, timeSeriesData);
        if (!createdTimeSeries) {
          return;
        }
        this.valueType = {
          ...this.valueType,
          [createdTimeSeries.id]: timeSeriesData.valueType
        };
        await this.fetchTimeSeriesActualValue(createdTimeSeries.id, kpi);

        return createdTimeSeries;
      } catch (error) {
        console.error('Error in createTimeSeries', error);
      }
    },
    async addTimeSeriesPoint(id, body, kpi) {
      body = {
        ...body
      };

      const addedTimeSeriesPoint = await post(`/timeseries/${id}/add`, body);

      await this.fetchTimeSeriesActualValue(id, kpi);
      return addedTimeSeriesPoint;
    },
    async updateTimeSeriesPoint(id, timestamp, body, kpi) {
      body = {
        ...body
      };

      const updatedTimeSeriesPoint = await put(`/timeseries/${id}/${timestamp}`, body);

      await this.fetchTimeSeriesActualValue(id, kpi);
      return updatedTimeSeriesPoint;
    },
    removeActualValue(id) {
      delete this.timeSeriesActualValue[id];
    },
    setActualValue(id, value) {
      if (!value) {
        this.removeActualValue(id);
      } else {
        this.timeSeriesActualValue[id] = value;
      }
    }
  },
  getters: {
    getActualValue(state) {
      return (id) => state.timeSeriesActualValue[id] ?? {value: ''};
    }
  }
});
