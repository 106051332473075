<template>
  <div class="BoardTable">
    <div class="BoardTable-header">
      <IobHeaderAction
        ref="headerActionRef"
        :title="$t('boards.label')"
        :sub-title="bordsCount"
        :search-placeholder="$t('boards.search')"
        :grid-view-button="false"
        button-icon="Plus"
        :sort-button="false"
        has-search-input
        filter-button
        :filter-details="filterDetails"
        :list-view-button="false"
        @button-clicked="handleBoardAdd"
        @filter="() => displayFilterPopup()"
      />
    </div>
    <div
      class="BoardTable-body"
      @scroll="handleScroll"
    >
      <IobTable
        :table-headers="boardHeaderConfig"
        :rows="formattedBoards"
        :more-options-menu="boardDetailsMenuItems"
        @on-click-table-row="(index) => handleTableRowClick(index)"
        @onDetailsActionClick="handleActionClick"
      />
    </div>
    <iob-dialog-box
      v-if="isDeletePopupVisible"
      :title="$t('board.delete.title')"
      :content="$t('board.delete.description')"
      :submit-action="$t('board.delete.button')"
      :cancel-action="$t('board.cancel')"
      @submitAction="onDeleteBoard"
      @cancelAction="isDeletePopupVisible = false"
    />
    <filter-popup
      v-if="isFilterPopupVisible"
      :attribute-filter-data="boardFilters"
      :anchor-el="filterBoxAnchorElement"
      :attribute-filters="attributeFilters"
      :attribute-filter-title="$t('filter.otherFilters')"
      @close="isFilterPopupVisible = false"
      @add-filter="handleAddFilter"
      @remove-filter="(item) => handleRemoveFilter(item)"
      @select-tree-item="(item, filter, filterKey) => handleSelectTreeItem(item, filter, filterKey)"
      @clear="clearFilter"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useWorkspaceStore } from 'SRC/piniaStore/workspace/workspace';
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useRouter } from 'vue-router';
import { boardHeaderConfig, BOARD_DETAILS_MENU_ITEMS, LEVEL_NAME_ATTRIBUTE, BOARD_ATTREBUTES_FILTERS } from 'SRC/globals/constants';
import { isDate, formatRelativeDate } from 'SRC/utils/utils';
import { useI18n } from 'vue-i18n';
import FilterPopup from '../components/FilterPopup/FilterPopup.vue';
import { findItemInTree } from './utils';

const router = useRouter();
const { t } = useI18n();
const isLoading = ref(false);
const isDeletePopupVisible = ref(false);
const selectedBoardId = ref(null);
const isCreating = ref(false);
const boardDetailsMenuItems = ref(BOARD_DETAILS_MENU_ITEMS);
const workspaceStore = useWorkspaceStore();
const usersStore = useUsersStore();
const appStore = useAppStore();
const dataStore = useDataStore();
const bordsCount = computed(() => workspaceStore.boards.length);
const nextBoardsQuery = computed(() => workspaceStore.nextBoardsQuery);
const levels = computed(() => appStore.levels);
const levelsTree = computed(() => appStore.levelsTree);
const userLevel = computed(() => dataStore.userLevel);
const boardFilters = ref({});
const attributeFilters = ref(BOARD_ATTREBUTES_FILTERS);
const filterDetails = computed(() => {
  const filterCountLabel = Object.keys(boardFilters.value).length ? ` : ${Object.keys(boardFilters.value).length}` : '';
  return {
    label: `Filter${filterCountLabel}`,
    icon: 'ListFilter',
    showLeftIcon: true,
    showRightIcon: false
  };
});
const isFilterPopupVisible = ref(false);
const filterBoxAnchorElement = ref(null);
const headerActionRef = ref(null);

const formattedBoards = computed(() => {
  const tableRows = workspaceStore.boards.map((board) => {
    const levelName = levels.value[board.level] ? levels.value[board.level].attributes[LEVEL_NAME_ATTRIBUTE] : 'None';
    return {
      ...board,
      boardName: {
        title: board.name,
        showCover: true,
        coverImg: '',
        withPreview: true
      },
      creator: {
        name: getDisplayName(usersStore.getUserInfo(board.creator)) || ''
      },
      level: {
        name: levelName
      },
      activity: getBoardActivity(board.lastActivityDateTime),
      more: ['MoreHorizontal']
    };
  });

  return tableRows;
});

const displayFilterPopup = () => {
  isFilterPopupVisible.value = true;
  filterBoxAnchorElement.value = headerActionRef.value.$el.querySelector('#filterButton');
};

const handleBoardAdd = async () => {
  if (isCreating.value) {
    return;
  }
  isCreating.value = true;
  const createdBoard = await workspaceStore.createBoard();
  if (createdBoard) {
    router.push({
      path: `/board/${createdBoard.id}`,
      query: { openSettings: true }
    });
  }
  isCreating.value = false;
};

const handleActionClick = async ({actionId, index}) => {
  selectedBoardId.value = formattedBoards.value[index].id;
  if (actionId === 'deleteAction') {
    isDeletePopupVisible.value = true;
  }
};

const onDeleteBoard = async () => {
  const isDeleted = await workspaceStore.deleteBoard(selectedBoardId.value);
  if (isDeleted) {
    isDeletePopupVisible.value = false;
    selectedBoardId.value = null;
  }
};

const getDisplayName = (user) => {
  if (!user || !user.firstname || !user.lastname) {
    return '';
  }
  return `${user.firstname} ${user.lastname}`;
};

const handleScroll = async (event) => {
  const container = event.target;
  const hasReachedEnd = container.scrollHeight - container.scrollTop - container.clientHeight <= 10;
  if (hasReachedEnd && nextBoardsQuery.value && !isLoading.value) {
    await fetchBoards(false);
  }
};

const fetchBoards = async (resetParams = false) => {
  isLoading.value = true;
  const filters = Object.entries(boardFilters.value).reduce((acc, [key, filter]) => {
    acc[key] = filter.selectedValues.map((value) => value.id);
    return acc;
  }, {});

  await workspaceStore.filterBoards(filters, resetParams);
  isLoading.value = false;
};

const getBoardActivity = (modificationData) => {
  const formatedDate = formatRelativeDate(new Date(modificationData));
  const date = isDate(formatedDate) ? `${t('boards.on')} ${formatedDate}` : t(`date.${formatedDate}`);
  const boardStatus = t('boards.edited');
  return `${boardStatus} ${date}`;
};

const setDefaultFilters = () => {
  if (userLevel.value && levelsTree.value) {
    const selectedLevel = findItemInTree(levelsTree.value, userLevel.value);
    boardFilters.value = {
      level: {
        selectedValues: selectedLevel ? [{
          name: 'level',
          id: selectedLevel.id,
          text: selectedLevel.attributes[LEVEL_NAME_ATTRIBUTE],
          checked: true,
          type: 'menu',
          withID: true
        }] : [],
        options: levelsTree.value,
        isSingleSelect: false,
        maxDisplayedItems: 5,
        treeValues: {labelField: LEVEL_NAME_ATTRIBUTE, status: selectedLevel, showLabelField: true}
      }
    };
  }
};

const handleAddFilter = (item) => {
  boardFilters.value[item.name] = {
    selectedValues: [],
    options: levelsTree.value,
    isSingleSelect: false,
    maxDisplayedItems: 5,
    treeValues: {labelField: LEVEL_NAME_ATTRIBUTE, showLabelField: true}
  };
  attributeFilters.value[item.name].selected = true;
};

const handleRemoveFilter = (item) => {
  delete boardFilters.value[item];
  attributeFilters.value[item].selected = false;
  fetchBoards(true);
};

const handleSelectTreeItem = (item, filter, filterKey) => {
  const index = boardFilters.value[filterKey].selectedValues.findIndex((value) => value.id === item.id);
  if (index === -1) {
    boardFilters.value[filterKey].selectedValues.push({
      name: filterKey,
      id: item.id,
      text: item.attributes[filter.treeValues.labelField],
      checked: true,
      type: 'menu',
      withID: true
    });
  } else {
    if (boardFilters.value[filterKey].selectedValues.length === 1) {
      delete boardFilters.value[filterKey];
      attributeFilters.value[filterKey].selected = false;
    } else {
      boardFilters.value[filterKey].selectedValues.splice(index, 1);
    }
  }
  fetchBoards(true);
};

watch(levelsTree, async () => {
  setDefaultFilters();
  fetchBoards(true);
});

onMounted(() => {
  setDefaultFilters();
  fetchBoards(true);
});

const handleTableRowClick = (index) => {
  router.push(`/board/${formattedBoards.value[index].id}`);
};
</script>

<style lang="scss" scoped>
.BoardTable {
  display: flex;
  flex-direction: column;
  gap: var(--app-template-page-gap-main);
  height: 100%;
}

.BoardTable-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 var(--size-tokens-structure-page-padding-horizontal, 24px);
  z-index: 0;
}
</style>
