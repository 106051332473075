import { defineStore } from 'pinia';
import { ref } from 'vue';
import {isAuthenticated, logout} from '@iobeya/common-tools';

export const useAuthStore = defineStore('auth', () => {
  const authenticated = ref(isAuthenticated());

  const signOut = () => {
    logout();
    authenticated.value = false;
  };

  const setAuthenticated = (value) => {
    authenticated.value = value;
  };

  return {
    authenticated,
    signOut,
    setAuthenticated
  };
});

