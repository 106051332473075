<template>
  <dialog-box :dialog-box-specific-style="dialogBoxStyle">
    <div class="Routine-modalWrapper">
      <div class="Routine-modalSidebarLeft">
        <img
          :src="routineModalImage"
          alt="Nothing to show Image"
        >
      </div>
      <div class="Routine-modalContent">
        <div class="routine-form">
          <div class="Routine-modalHeader-group">
            <div class="Routine-modalHeader">
              <span
                v-if="!props.editEnabled"
                class="Routine-modalTitle"
              >  {{ $t('routines.new') }} </span>
              <span
                v-else
                class="Routine-modalTitle"
              > {{ $t('routines.editRoutine') }}</span>
              <span class="Routine-subTitle">Configure a routine for you and your team</span>
            </div>
            <icon-loader
              class="Routine-iconClose"
              name="X"
              stroke="black"
              color="black"
              @click="cancel"
            />
          </div>
          <form class="Routine-modalForm">
            <div class="Routine-formGroup">
              <div class="py-3">
                {{ $t('routines.name') }}
              </div>
              <div class="Routine-inputGroup">
                <iob-icon-dropdown
                  :color="formData.icon.color"
                  :icon-name="formData.icon.name"
                  :background-img="formData.icon.backgroundImg"
                  :selected-item="formData.icon"
                  size="xl"
                  :items="mappedIcons"
                  @dropdownElementClick="(value) => handleIconSelect(value)"
                />
                <input
                  v-model="formData.name"
                  :class="{ 'Routine-field--error': errors['name'], 'Routine-field': true }"
                  :type="'text'"
                  :placeholder="$t('routines.nameRoutine')"
                >
              </div>
              <span
                v-if="errors['name']"
                class="Routine--error"
              >{{ $t('routines.specifyName') }}</span>
            </div>
            <div class="Routine-formGroup">
              <div class="py-3">
                {{ $t('routines.description') }}
              </div>
              <textarea
                v-model="formData.description"
                :class="{ 'Routine-field--error': errors['description'], 'Routine-field': true }"
                :type="'text'"
                :placeholder="$t('routines.setDescription')"
              />
              <span
                v-if="errors['description']"
                class="Routine--error"
              >{{ $t('routines.specifyDescription') }}</span>
            </div>
            <div class="Routine-formGroup">
              <div class="py-3">
                {{ $t('routines.level') }}
              </div>
              <tree-node
                id="Routine-levelDropdown"
                :options="appStore.levelsTree"
                label-field="level-name"
                :status="formData.level"
                @dropdown-element-click="(value) => handleLevelChange(value)"
              />
              <span
                v-if="errors?.level"
                class="Routine--error"
              >{{ $t('routines.specifyLevel') }}</span>
            </div>
            <div class="Routine-formGroup">
              <div class="py-3">
                {{ $t('routines.url') }}
              </div>
              <input
                v-model="formData.url"
                :class="{ 'Routine-field--error': errors['url'], 'Routine-field': true }"
                :type="'text'"
                :placeholder="$t('routines.pasteUrl')"
                @keypress.enter.prevent="createRoutine()"
              >
              <span
                v-if="errors['url']"
                class="Routine--error"
              >{{ $t('routines.specifyValidUrl') }}</span>
            </div>
          </form>
        </div>
        <div class="Routine-buttonGroup">
          <IobButton
            size="medium"
            color="secondarySoft"
            type="filled"
            :disabled="false"
            :label="$t('routines.cancel')"
            left-icon="()=>({})"
            :show-left-icon="false"
            :selected="false"
            :rounded="false"
            :show-right-icon="false"
            :timeframe="false"
            :is-focused="false"
            @click="cancel"
          />
          <IobButton
            size="medium"
            color="primary"
            type="filled"
            :disabled="false"
            :label="!props.editEnabled ? $t('routines.create') : $t('routines.save')"
            left-icon="()=>({})"
            :show-left-icon="false"
            :selected="false"
            :rounded="false"
            :show-right-icon="false"
            :timeframe="false"
            :is-focused="false"
            @click="createRoutine"
          />
        </div>
      </div>
    </div>
  </dialog-box>
</template>

<script setup>
import { reactive, defineProps, defineEmits } from 'vue';
import { post, put } from 'SRC/api/index.js';
import { sanitizeUrl, unsanitizeUrl} from 'SRC/utils/utils';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useAppStore } from 'SRC/piniaStore/app/app';
import {ROUTINE_ICONS} from '../../globals/constants';
import routineModalImage from 'SRC/assets/illustrations/routineModal.png';
import {getLevelAttributeValue} from '../utils';
const appStore = useAppStore();
const dataStore = useDataStore();
const routineDataTypeID = 'abc4379d-0b7a-4b64-8fae-f7857cf1b3f7';

const emit = defineEmits(['cancel']);
const props = defineProps({
  editEnabled: {
    type: Boolean,
    required: true,
    default: false
  },
  routine: {
    type: Object,
    required: false,
    default: () => ({})
  },
  userLevel: {
    type: String,
    required: false,
    default: ''
  }
});

const mappedIcons = Object.entries(ROUTINE_ICONS).map(([key, value]) => ({...value, name: key }));
const dialogBoxStyle = `
  position: absolute;
  width: 1024px;
  height: 680px;
  left: calc(50% - 512px);
  top: calc(50% - 340px);
  border-radius: var(--button-radius-large);
  padding: 0;`
;

let formData = reactive({
  icon: mappedIcons[0],
  name: '',
  description: '',
  level: { 'level-name': '', colors: '', id: '' },
  url: ''
});

const errors = reactive({
  name: false,
  description: false,
  level: false,
  url: false
});

if (props.editEnabled) {
  const editedRoutine = props.routine;
  const icon = mappedIcons.find((icon) => icon.name === editedRoutine.icon);
  editedRoutine.icon = icon ? icon : editedRoutine.icon;
  editedRoutine.url = unsanitizeUrl(editedRoutine.url);
  editedRoutine.level = getLevelAttributeValue(Object.values(appStore.levelsTree), editedRoutine.level);
  formData = {...formData, ...editedRoutine};
} else if (props.userLevel) {
  formData.level = getLevelAttributeValue(Object.values(appStore.levelsTree), props.userLevel);
}

const validate = () => {
  const isValidUrl = (url) => {
    try {
      const obj = new URL(url);
      if (obj.protocol === 'http:' || obj.protocol === 'https:') {
        return true;
      }
    } catch (e) {
      return false;
    }
  };
  Object.assign(errors, {
    name: !formData.name.trim(),
    description: !formData.description.trim(),
    level: !formData.level?.id,
    url: !isValidUrl(formData.url)
  });
  return !Object.values(errors).includes(true);
};

const createRoutine = async () => {
  let routineRespance;
  if (validate()) {
    if (!props.editEnabled) {
      const body = {
        typeId: routineDataTypeID,
        attributes: {
          icon: formData?.icon?.name,
          name: formData.name,
          description: formData.description,
          level: formData.level.id,
          url: sanitizeUrl(formData.url)
        }
      };
      routineRespance = await post('/dataset-elements', body);

    } else {
      const body = {
        attributes: {
          icon: formData?.icon?.name,
          name: formData.name,
          description: formData.description,
          level: formData.level.id,
          url: sanitizeUrl(formData.url)
        }
      };
      routineRespance = await put(`/dataset-elements/${formData.routineId}`, body);
    }
    dataStore.datasetElements[routineRespance.id] = props.userLevel === formData.level.id ? routineRespance : {};
    cancel();
  }
};

const handleIconSelect = (icon) => {
  formData.icon = icon;
};

const handleLevelChange = (level) => {
  formData.level = level;
};

const cancel = () => {
  emit('cancel');
};
</script>

<style lang="scss" scoped src="./RoutineModal.scss" />
