<template>
  <div class="ImportHierarchy">
    <IobHeaderAction
      :title="$t('hierarchy.label')"
      :sub-title="hierarchiesCount"
      :search-placeholder="$t('hierarchy.search')"
      button-icon="CloudUpload"
      :filter-button="false"
      :sort-button="false"
      :list-view-button="false"
      :has-search-input="false"
      :more-btn="false"
    />
    <div class="ImportHierarchy-content">
      <div class="ImportHierarchy-nothing-to-show">
        <img
          :src="NothingToShowImg"
          alt="Nothing to show Image"
        >
        <div class="ImportHierarchy-nothing-to-show-text">
          Nothing to show yet
        </div>
        <div class="ImportHierarchy-instructions">
          Follow the instructions to build your hierarchy
        </div>
      </div>
      <div class="ImportHierarchy-steps-container">
        <div class="ImportHierarchy-steps">
          <ImportHierarchyStep
            step-number="1"
            description="Download a hierarchy template to build your own organizational structure and levels"
            :is-download="true"
            :is-import="false"
          />
          <ImportHierarchyStep
            step-number="2"
            description="Import your file"
            :is-download="false"
            :is-import="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ImportHierarchyStep from '../ImportHierarchyStep/ImportHierarchyStep.vue';
import NothingToShowImg from 'SRC/assets/illustrations/nothingToShow.svg';

const hierarchiesCount = ref(0);
</script>

<style lang="scss" scoped src="./ImportHierarchy.scss" />
