<template>
  <iob-sidebar
    size="small"
    class="NewSidebar"
    elevation="1"
    style="height: 100%;"
  >
    <iob-sidebar-header v-if="props.displayHeader">
      <iob-main-nav-header
        :is-search-disabled="isSearchDisabled"
        :is-plus-button-disabled="isPlusButtonDisabled"
        @update:searchValue="($event) => emit('update:searchValue', $event)"
      />
    </iob-sidebar-header>
    <div class="NewSidebar-content-scrollable">
      <iob-menu>
        <iob-menu-item
          v-for="(item, idx) in props.top"
          :key="idx"
          :selected="isSelected('top', idx)"
          :text="item.label"
          :icon-name="item.icon"
          @click.stop="() => selectItem({
            section: 'top',
            menuItemIdx: idx,
            submenuIdx: -1,
            submenuItemIdx: -1
          })"
        />
      </iob-menu>
      <iob-menu-divider v-if="props.displayDivider && props.other.length" />
      <iob-menu v-if="props.other.length">
        <iob-menu-item
          v-for="(item, idx) in props.other"
          :key="idx"
          :selected="isSelected('other', idx)"
          :text="item.label"
          :icon-name="item.icon"
          @click.stop="() => selectItem({
            section: 'other',
            menuItemIdx: idx,
            submenuIdx: -1,
            submenuItemIdx: -1
          })"
        />
      </iob-menu>
      <iob-menu-divider v-if="props.displayDivider && props.main.length > 0" />
      <div>
        <iob-menu>
          <iob-menu-item
            v-for="(item, idx) in props.main"
            :key="idx"
            :text="item.label"
            :icon-name="item.icon"
            :expandable="item.expandable"
            :selected="isSelected('main', idx)"
            @click.stop="() => selectItem({
              section: 'main',
              menuItemIdx: idx,
              submenuIdx: -1,
              submenuItemIdx: -1
            })"
          >
            <iob-submenu
              v-for="(cat, catIdx) in item.categories"
              :key="catIdx"
              :category-name="cat.name"
              @category-select="($event)=> handleCategoryClick({
                section: 'main',
                menuItemIdx: catIdx,
                categoryIdx: catIdx,
                submenuIdx: -1,
                submenuItemIdx: -1
              })"
            >
              <iob-submenu-item
                v-for="(submenuItem, smIdx) in cat.items"
                :key="smIdx"
                :text="submenuItem.label"
                :action-button="false"
                :selected="isSelected('main', idx, catIdx, smIdx)"
                @click.stop="() => selectItem({
                  section: 'main',
                  menuItemIdx: idx,
                  submenuIdx: catIdx,
                  submenuItemIdx: smIdx
                })"
              />
            </iob-submenu>
          </iob-menu-item>
        </iob-menu>
      </div>
      <iob-menu-divider v-if="props.displayDivider" />
      <iob-sidebar-footer>
        <iob-menu>
          <iob-menu-item
            v-for="(item, idx) in props.footer"
            :key="idx"
            :text="item.label"
            :icon-name="item.icon"
            :selected="isSelected('footer', idx)"
            @click="() => selectItem({
              section: 'footer',
              menuItemIdx: idx,
              submenuIdx: -1,
              submenuItemIdx: -1
            })"
          />
        </iob-menu>
      </iob-sidebar-footer>
    </div>
  </iob-sidebar>
</template>

<script setup>

import { defineProps, defineEmits, reactive } from 'vue';

const props = defineProps({
  top: {
    type: Array,
    default: () => ([])
  },
  main: {
    type: Array,
    default: () => ([])
  },
  other: {
    type: Array,
    default: () => ([])
  },
  footer: {
    type: Array,
    default: () => ([])
  },
  selectedItem: {
    type: Object,
    default: () => ({})
  },
  displayHeader: {
    type: Boolean,
    default: true
  },
  displayDivider: {
    type: Boolean,
    default: true
  },
  isSearchDisabled: {
    type: Boolean,
    default: false
  },
  isPlusButtonDisabled: {
    type: Boolean,
    default: false
  }
});
const selected = reactive(props.selectedItem || {
  section: '',
  menuItemIdx: -1,
  submenuIdx: -1,
  submenuItemIdx: -1
});

const emit = defineEmits(['item-select', 'category-select', 'update:searchValue']);
const handleCategoryClick = (event) => {
  emit('category-select', event);
};
const selectItem = ({ section, menuItemIdx, submenuIdx, submenuItemIdx }) => {
  selected.section = section;
  selected.menuItemIdx = menuItemIdx;
  selected.submenuIdx = submenuIdx;
  selected.submenuItemIdx = submenuItemIdx;

  emit('item-select', { section, menuItemIdx, submenuIdx, submenuItemIdx });
};

const isSelected = (section, menuItemIdx = -1, submenuIdx = -1, submenuItemIdx = -1) => {
  if (submenuIdx === -1) {
    return selected.section === section && selected.menuItemIdx === menuItemIdx;
  } else {
    return selected.section === section &&
      selected.menuItemIdx === menuItemIdx && selected.submenuIdx === submenuIdx && selected.submenuItemIdx === submenuItemIdx;
  }
};
</script>
<style lang="scss" scoped>
.NewSidebar {
  height: 100%;
}

.NewSidebar-content-scrollable {
  overflow-y: auto;
}
</style>
