const utils = {
  getNextPage: async (response, data, fn, body = null, isRelative = false, circuitBreakerInfo = {}) => {
    while (response.next) {
      response = isRelative ? await fn(response.next, null, true, true, circuitBreakerInfo) :
        await fn(response.next, body, true, false, circuitBreakerInfo);
      const nextPageResponseData = response.data;
      if (response && nextPageResponseData) {
        data = data.concat(nextPageResponseData);
      }
    }
    return data;
  },
  createRequestBodyForNewRelation: (relation, eligibleDatasetElementId, id) => relation === 'parent'
    ? [{ datasetElementParentId: eligibleDatasetElementId, datasetElementChildId: id }]
    : [{ datasetElementParentId: id, datasetElementChildId: eligibleDatasetElementId }],
  formatDependencies: (createdDependencies, dataTypes) => {
    const data = {};
    const dependencies = createdDependencies;
    dependencies.forEach((element) => {
      const dataTypeName = dataTypes[element.typeId].name;
      const relationConfigAttrs = dataTypes[element.typeId].editorConfig.relationConfig.attributes;
      let dependency = {};
      if (element) {
        dependency = element;
      }
      if (data[dataTypeName]) {
        dependencies[dataTypeName] = data[dataTypeName].push({dependency, relationConfigAttrs});
      } else {
        data[dataTypeName] = [{dependency, relationConfigAttrs}];
      }
    });
    return data;
  }
};

export default utils;
