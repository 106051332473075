import { evaluate } from '@iobeya/vms-formula-evaluator/evaluator';
import { buildVmsTree } from '@iobeya/vms-formula-evaluator/vmsExprTree';
import DatasetElementContext from '../../views/DataView/DatasetElementContext';

export const evaluateFormula = async (elementData, dataType, formula, allDataTypes) => {
  const evalCtx = new DatasetElementContext({ ...elementData, dataType, allDataTypes});
  try {
    const res = await evaluate(buildVmsTree(formula), evalCtx);
    return res;
  } catch (e) {
    console.error('Error evaluating formula', e);
    return null;
  }
};
/** @description
 * this function formats datasetElements array in a object, with keys being datasetElementIds and value the datasetElement
 * itself
 * the function will add an empty title attribute for elements that don't have it
 * @argument datasetElements array of datasetElements
 */
export const formatDatasetElements = (datasetElements) => datasetElements
  .reduce((acc, datasetElement) => {
    const hasTitle = datasetElement?.attributes?.title;
    if (hasTitle) {
      acc[datasetElement.id] = datasetElement;
    } else {
      acc[datasetElement.id] = {
        ...datasetElement,
        attributes: {
          ...datasetElement.attributes,
          title: ''
        }
      };
    }
    return acc;
  }, {});

/**
 * @description
 * this function updates the attributes of a datasetElement under another datasetElements Children/dependencies
 * @param {*} datasetElements
 * @param {*} attributes
 * @param {*} elementId
 * @param {*} relativesKind
 * @returns
 */
export const updateAttributeInRelations = (datasetElements, attributes, elementId, relativesKind) => {
  const relatives = Object.values(datasetElements).filter(
    (dsValue) =>
      dsValue[relativesKind] &&
      dsValue[relativesKind].length > 0 &&
      dsValue[relativesKind].find((child) => child.id === elementId)
  );
  if (!relatives || relatives.length === 0) {
    return {};
  }
  let relativesIds = [];
  const updatedDatasetElements = relatives.reduce((acc, relative) => {
    relativesIds = [...relativesIds, relative.id];
    return {
      ...acc,
      [relative.id]: {
        ...relative,
        [relativesKind]: relative[relativesKind].map((element) => {
          if (!(element.id === elementId)) {
            return element;
          }
          return {
            ...element,
            attributes: { ...element.attributes, ...attributes }
          };
        })
      }
    };
  }, {});
  return {updatedDatasetElements, relativesIds};
};
