
export const makeRequest = async (breaker, url, body, toJson, isRelative, interrupts) => {
  const fallBack = (() => false);
  breaker.on('success', (result) => result);
  breaker.on('failure', () => {
    console.log('failure', breaker.stats);
  });
  breaker.fallback(fallBack);
  console.log('before fire');
  return breaker.fire(url, body, toJson, isRelative, interrupts);
};

