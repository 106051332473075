<template>
  <div class="BulkActionPanel">
    <div class="BulkActionPanel-content">
      <div class="BulkActionPanel-badge">
        <p class="BulkActionPanel-text">
          {{ props.elementsCount }}
        </p>
      </div>
      <p class="BulkActionPanel-text">
        {{ props.text }}
      </p>
    </div>
    <div class="BulkActionPanel-separator" />
    <button
      class="BulkActionPanel-button BulkActionPanel-button-delete"
      @click="performAction()"
    >
      <p class="BulkActionPanel-text">
        {{ props.action }}
      </p>
    </button>

    <button
      class="BulkActionPanel-button BulkActionPanel-button-close"
      @click="handleClosePanel"
    >
      <icon-loader
        name="X"
        size="default"
        color="#9491A8"
      />
    </button>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useDataStore } from 'SRC/piniaStore/data/data';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useI18n } from 'vue-i18n';

const appStore = useAppStore();
const props = defineProps({
  elementsCount: {
    type: String,
    default: '0'
  },
  elements: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: 'action'
  },
  action: {
    type: String,
    default: ''
  }
});
const { t } = useI18n();
const dialogBoxFlags = computed(() => appStore.dialogBoxFlags);

const handleDeleteDatasetElements = async () => {
  const dataStore = useDataStore();
  const ids = props.elements
    .map((id) => Object.values(dataStore.datasetElements).find(({ attributes }) => id === attributes['friendly-id']))
    .map((el) => el && el.id);

  if (ids.length === 0) {
    return;
  }
  const response = await dataStore.deleteAllSelectedDatasetElements(
    ids
  );
  if (response) {
    dataStore.clearSelectedDatasetElements();
    appStore.setDialogBoxFlags({
      ...dialogBoxFlags.value,
      isDeleteDatasetElementDialogOpened: false
    });
    appStore.setDialogBoxInfo(null);
    appStore.setNotificationDisplayed({
      message: 'Moved to trash',
      show: true
    });
  }
};

const performAction = async () => {
  if (props.action === 'Delete') {
    appStore.setDialogBoxInfo({
      title: t('data.delete-element.dialog-box.title'),
      content: t('data.delete-element.dialog-box.content'),
      'submit-action': t('common.delete'),
      'cancel-action': t('common.cancel'),
      submitFunc: async () => await handleDeleteDatasetElements(),
      cancelFunc: () => appStore.setDialogBoxFlags({
        ...dialogBoxFlags.value,
        isDeleteDatasetElementDialogOpened: false
      })
    });

    appStore.setDialogBoxFlags({
      ...dialogBoxFlags.value,
      isDeleteDatasetElementDialogOpened: true
    });
  }
};
const handleClosePanel = () => {
  const dataStore = useDataStore();
  dataStore.clearSelectedDatasetElements();
};
</script>

<style lang="scss" scoped src="./BulkActionPanel.scss" />
