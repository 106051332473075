<!-- eslint-disable max-len -->
<template>
  <div>
    <new-sidebar
      v-if="!isSettingsMenu"
      :top="mainMenu.top"
      :main="mainMenu.main"
      :other="mainMenu.other"
      :footer="mainMenu.footer"
      :selected-item="selectedItem"
      :is-search-disabled="true"
      :is-plus-button-disabled="true"
      @item-select="onItemSelect"
      @category-select="onItemSelect"
      @update:searchValue="onSearchValueUpdate"
    />
    <div v-else>
      <div class="NavigationMenu-back-button-container">
        <button
          class="NavigationMenu-back-button"
          @click="goBackToMainMenu"
        >
          <IconLoader
            name="ArrowLeft"
            :has-background-color="false"
            class="NavigationMenu-arrow-icon"
          />
          <span class="NavigationMenu-settings-text">Settings</span>
        </button>
      </div>
      <new-sidebar
        :top="settingsMenu.top"
        :selected-item="selectedItem"
        :display-header="false"
        :display-divider="false"
        @item-select="onItemSelect"
        @category-select="onItemSelect"
      />
    </div>
  </div>
</template>
<script setup>

import { reactive, onMounted, ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import NewSidebar from '../NewSidebar.vue';
import { useAppStore } from 'SRC/piniaStore/app/app';
import {HIERARCHY} from 'GLOBALS/constants';

const appStore = useAppStore();
const router = useRouter();
const searchInput = ref('');
const items = ref([]);
const isSettingsMenu = ref(false);

const datatypes = computed(() => appStore.datatypes);

onMounted(async () => {
  await appStore.fetchFormulas();
});

watch(() => datatypes.value,
  (datatypes) => {
    const newElementsDataTypes = Object.values(datatypes).map((element) => {
      const label = element.name === 'Objective' ? `${element.name} (CBN, CI Goal, FE)` : element.name;
      return { label, path: `/elements/${element.id}`};
    });
    items.value = [...newElementsDataTypes];
  }, { deep: true }
);

const mainMenu = reactive({
  top: [
    {
      label: 'Home',
      icon: 'Home',
      path: '/home'
    },
    {
      label: 'Boards',
      icon: 'Board',
      path: '/boards'
    }
  ],
  other: [],
  main: [
    { label: 'Data', icon: 'List', expandable: true,
      categories: [
        { name: 'All elements', items, path: '/elements'
        }]}],
  footer: [
    { label: 'Settings', icon: 'Settings', path: 'settings'}
  ]
});

const settingsMenu = reactive(
  {
    top: [
      {label: 'YOUR ACCOUNT'},
      {label: 'My Profile', icon: 'User'},
      {label: 'Preferences', icon: 'Settings'},
      {label: 'SANOFI'},
      {label: 'Hierarchy', icon: 'Network', path: HIERARCHY.VIEW_PATH}
    ]
  });

function getMenuItemFromPath(path) {
  let result = null;
  const itemsData = isSettingsMenu.value ? settingsMenu : mainMenu;
  Object.entries(itemsData).forEach(([section, menu]) => {
    menu.forEach((item, idx) => {
      if (item.path === path) {
        result = {
          section,
          menuItemIdx: idx,
          submenuIdx: -1,
          submenuItemIdx: -1
        };
      }
      if (item.categories) {
        item.categories.forEach((category, catIdx) => {
          category.items.forEach((subitem, subidx) => {
            if (subitem.path === path) {
              result = {
                section,
                menuItemIdx: idx,
                submenuIdx: catIdx,
                submenuItemIdx: subidx
              };
            }
          });
        });
      }
    });
  });

  return result;
}

function getPathFromMenuItem(menuItem) {
  const itemsData = isSettingsMenu.value ? settingsMenu : mainMenu;
  const item = itemsData[menuItem.section][menuItem.menuItemIdx];
  if (menuItem.submenuIdx !== -1 && menuItem.submenuItemIdx !== -1
  && menuItem.categoryIdx === -1) {
    return item.categories[menuItem.submenuIdx].items[menuItem.submenuItemIdx].path;
  } else if (menuItem.submenuIdx === -1 && menuItem.submenuItemIdx === -1 && menuItem.categoryIdx !== -1
  ) {
    return item.categories[menuItem.menuItemIdx].path;
  } else {
    return item.path;
  }
}

const selectedItem = computed(() => {
  const path = router.currentRoute?.value.path.split('/')[1];

  return getMenuItemFromPath(path);
});

function onItemSelect({ section, menuItemIdx, categoryIdx = -1, submenuIdx = -1, submenuItemIdx = -1 }) {
  const path = getPathFromMenuItem({
    section,
    menuItemIdx,
    categoryIdx,
    submenuIdx,
    submenuItemIdx
  });
  const pathActions = {
    'settings': () => isSettingsMenu.value = true,
    'default': (path) => router.push(path)
  };

  if (path) {
    (pathActions[path] || pathActions.default)(path);
  }
}

function onSearchValueUpdate(searchValue) {
  searchInput.value = searchValue;
}
function goBackToMainMenu() {
  isSettingsMenu.value = false;
}

</script>
<style lang="scss" scoped src="./NavigationMenu.scss" />
