<template>
  <div :class="`SummaryDetailRow grid grid-cols-4 gap-x-4`">
    <span class="SummaryDetailRow-title col-span-1">{{ props.title }}</span>
    <span class="SummaryDetailRow-content col-span-3">
      <span class="SummaryDetailRow-content-info-alert">{{ props.alertInfo }}</span>
      <span class="SummaryDetailRow-content-info">{{ props.info }}</span>
    </span>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: ''
  },
  info: {
    type: String,
    required: true,
    default: ''
  },
  alertInfo: {
    type: String,
    required: false,
    default: ''
  }
});
</script>

<style scoped lang="scss">
.SummaryDetailRow {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.SummaryDetailRow-title {
  color: #242230;
  font-weight: 500;
}

.SummaryDetailRow-content {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.SummaryDetailRow-content-info-alert {
  color: #D34343;
}

.SummaryDetailRow-content-info {
  color: #47435F;
}
</style>
