import { defineStore } from 'pinia';
import { get, post, del } from 'SRC/api/index.js';

export const useWorkspaceStore = defineStore('workspace', {
  state: () => ({
    boards: [],
    nextBoardsQuery: 'initial',
    boardParams: {
      page: 1,
      size: 20
    },
    rooms: []
  }),
  actions: {
    async fetchBoards(resetParams = false) {
      let results = {};
      if (resetParams) {
        this.nextBoardsQuery = 'initial';
        this.boards = [];
      }
      if (this.nextBoardsQuery === 'initial') {
        results = await get('/boards?page=1&size=25');
        if (!results) {
          return ;
        }
        this.boards = [...this.boards, ...results.data];
        this.nextBoardsQuery = results.next;
      } else {
        if (!this.nextBoardsQuery) {
          return;
        }
        results = await get(this.nextBoardsQuery, null, true, false);
        if (!results) {
          return ;
        }
        this.boards = [...this.boards, ...results.data];
      }
      this.nextBoardsQuery = results.next;
    },
    async filterBoards(body, resetParams = false) {
      let results = {};
      if (resetParams) {
        this.nextBoardsQuery = 'initial';
        this.boards = [];
      }
      if (this.nextBoardsQuery === 'initial') {
        results = await post('/boards/search?page=1&size=25', body);
        if (results) {
          this.boards = [...this.boards, ...results.data];
          this.nextBoardsQuery = results.next;
        }
      } else {
        if (this.nextBoardsQuery) {
          results = await post(this.nextBoardsQuery, body, true, false);
        }
        if (results) {
          this.boards = [...this.boards, ...results.data];
          this.nextBoardsQuery = results.next;
        }
      }
    },
    async createBoard(board = {
      name: 'New Board',
      displayLinks: false,
      level: localStorage.getItem('userLevel')
    }) {
      let result = null;
      const response = await post('/boards', board);

      if (response) {
        result = response;
        this.boards.push(result);
      }

      return result;
    },
    async deleteBoard(boardId) {
      try {
        const query = `ids=${boardId}`;
        const res = await del(`/boards?${query}`);
        if (res && res[0] && res[0].id) {
          const boardIndex = this.boards.findIndex((board) => board.id === res[0].id);
          this.boards.splice(boardIndex, 1);
          return true;
        }
      } catch {
        return false;
      }
    }
  }
});
