<template>
  <div class="MyWork-body-section">
    <div class="MyWork-body-section-container">
      <div class="MyWork-body-title-section">
        <div class="MyWork-body-title-text">
          {{ $t('home.my-work.label') }}
        </div>
      </div>
      <iob-button
        :size="myWorkAssignedToMe.size"
        :color="myWorkAssignedToMe.color"
        :type="myWorkAssignedToMe.type"
        :label="myWorkAssignedToMe.counter === 1 ?
          $t(myWorkAssignedToMe.label) :
          $t(myWorkAssignedToMe.labelPlural, { elementNumber: myWorkAssignedToMe.counter })"
        :show-left-icon="false"
        :show-right-icon="true"
        :right-icon="myWorkAssignedToMe.iconName"
        @click="handleMyWorkAction(myWorkAssignedToMe.id)"
      />
    </div>
    <div class="MyWork-body-list">
      <iob-elevation-button
        v-for="(myWork) in myOtherWorksList"
        :key="myWork.id"
        :action="myWork.id"
        :title-number="myWork.counter"
        :title="myWork.counter > 1 ? $t(myWork.titlePlural): $t(myWork.title)"
        :subtitle="$t(myWork.subtitle, {dayNumber: myWork.dayNumber})"
        :icon-color="myWork.iconColor"
        :icon-name="myWork.iconName"
        :kanji-image="myWork.kanjiImage"
        @action-select="handleMyWorkAction"
      />
    </div>
  </div>
</template>

<script setup>
import { useUsersStore } from 'SRC/piniaStore/users/users';
import { ref, computed, watch } from 'vue';
import { useAppStore } from 'SRC/piniaStore/app/app';
import { useRouter } from 'vue-router';
import { getHistoryTraces, buildMyWorksFilters } from '../DataView/utils';
import { useMyWorkStore } from 'SRC/piniaStore/myWork/myWork';
import { MY_WORKS } from 'SRC/globals/constants';
const router = useRouter();
const usersStore = useUsersStore();
const appStore = useAppStore();
const searchAttributes = computed(() => buildMyWorksFilters(currentUserId.value, dataTypes.value));
const currentUserId = computed(() => usersStore.currentUser?.id || '');

const myWorksList = ref(MY_WORKS);
const myWorkAssignedToMe = ref(myWorksList.value.assignedToMe);
const myOtherWorksList = computed(() => Object.values(myWorksList.value).filter((myWork) => myWork.id !== 'assignedToMe'));
const myWorkStore = useMyWorkStore();
const currentDate = new Date();
const dataTypes = computed(() => Object.values(appStore.datatypes));
watch(
  [searchAttributes, currentUserId, dataTypes.value],
  async ([newSearchAttributes, newCurrentUserId]) => {
    if (newSearchAttributes && newCurrentUserId) {
      let historyTraces ;
      Object.keys(newSearchAttributes).forEach(async (key) => {
        if (key === 'escalations' && dataTypes.value) {
          historyTraces = getHistoryTraces('escalated-on', ['last_7days'], dataTypes.value, currentDate);
        } else {
          historyTraces = null;
        }
        await myWorkStore.searchDatasetElements({
          query: '',
          attributes: newSearchAttributes[key].quickFilters,
          historyTraces
        });
        myWorksList.value[key].counter = myWorkStore.counter;
        myWorksList.value[key].path = newSearchAttributes[key].path;
      });

    }
  },
  { immediate: true, deep: true }
);
const handleMyWorkAction = (action) => {
  if (!myWorksList.value[action].counter) {
    return;
  }
  router.push({ path: myWorksList.value[action].path,
    query: {filter: action}
  });

};

</script>
<style lang="scss" scoped src="./MyWork.scss" />

