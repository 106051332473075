import { dataTypesIcons, INCOMPLETE_STATUS_LIST, ESCALATED_DATES_ENUM } from 'SRC/globals/constants';
import { getUserInitials } from 'SRC/utils/utils';
import i18n from 'SRC/plugins/locales/i18n';

const { t } = i18n.global;

export const getIcon = (dataTypeName, hierarchy) => ({
  icon: dataTypesIcons[dataTypeName]?.icons[hierarchy] || 'Target',
  color: dataTypesIcons[dataTypeName]?.color || 'primary'
});

export const getUser = (users, id) => {
  const user = users.value.filter((user) => user.id === id)[0];
  if (user) {
    return {
      name: `${user.firstname} ${user.lastname}`,
      img: user.picture
    };
  }
  return { name: '', img: '' };
};

export const formatElement = ({ users, datatypes, selectedDatasetElements, attributes, typeId }) => {
  const data = {};
  const dataTypeName = datatypes.value[typeId]?.name;
  const attributesNames = datatypes.value[typeId]?.attributes.map((attr) => attr.name);
  const id = attributes['friendly-id'];
  const isChecked = selectedDatasetElements.value.includes(id);
  const rank = ['id', 'type', 'title', 'status', 'owner', 'progress'];
  rank.forEach((name) => {
    const isDisabled = !attributesNames?.includes(name);
    switch (name) {
    case 'type':
      data.TYPE = { ...getIcon(dataTypeName, 'parent'), title: attributes.type || dataTypeName, id, checked: isChecked, isDisabled: false };
      break;
    case 'id':
      data.ID = id;
      break;
    case 'title':
      data.TITLE = { title: attributes.title, isDisabled };
      break;
    case 'status':
      data.STATUS = {statusList: [{ label: attributes.status }], isDisabled };
      break;
    case 'owner':
      data.OWNER = {...getUser(users, attributes.owner), isDisabled};
      break;
    case 'progress':
      data.PROGRESSION = {
        progress: isNaN(attributes.progress) ? 0 : attributes.progress,
        color: 'secondary', isDisabled
      };
      break;
    }
  });
  return data;
};

export const mergeAttributes = (attributes, attributesPanel) => {
  const mergedAttributes = attributes.reduce((acc, attr) => {
    const attributePanel = attributesPanel.find((filterAttr) => filterAttr.name === attr.name);
    if (attributePanel) {
      acc[attr.name] = { ...attributePanel, ...attr };
    } else {
      acc[attr.name] = { ...attr };
    }
    return acc;
  }, {});
  return mergedAttributes;
};

export const getRangeDates = (key, currentDate) => {

  let fromDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
  let toDate ;
  switch (key) {
  case 'today':
    break;
  case 'yesterday':
    fromDate.setDate(fromDate.getDate() - 1);
    toDate = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()));
    toDate.setUTCHours(23, 59, 59);
    break;
  case 'last_7days':
    fromDate.setDate(fromDate.getDate() - 7);
    break;
  case 'last_14days':
    fromDate.setDate(fromDate.getDate() - 14);
    break;
  case 'last_30days':
    fromDate.setDate(fromDate.getDate() - 30);
    break;
  case 'over-30days':
    fromDate = null;
    toDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
    toDate.setDate(currentDate.getDate() - 30);
    break;
  default:
    throw new Error(`Invalid key: ${key}`);
  }
  return {fromDate, toDate};
};

export const getHistoryTraces = (nameAttr, values, dataTypes, currentDate) => {
  const nameHistoryAttr = ATTRIBUTES_WITH_HISTORY[nameAttr]?.attributeHistoryTraces;
  const dataTypeFilters = dataTypes.filter((dataType) => dataType.attributes
    .some((data) => data.name === nameHistoryAttr));
  if (!dataTypeFilters.length) {
    return {};
  }
  const [{fromDate, toDate}] = values.map((key) => getRangeDates(key, currentDate));
  const dataTypeFiltersId = dataTypeFilters[0].id;
  const historyTraces = {
    dataTypeFilters: {
      [dataTypeFiltersId]: {
        type: [
          'element-change'
        ],
        attributes: {
          [nameHistoryAttr]: {
            dateTime: {
              ...fromDate && {fromDate},
              ...toDate && {toDate}
            },
            newValue: {
              not: [
                null
              ]
            }
          }
        }
      }
    }
  };
  return historyTraces;
};
const extraAttributes = {
  escalatedOn: {
    name: 'escalated-on',
    friendlyName: t('data.escalatedOn'),
    type: 'dates',
    hasSingleSelect: true,
    enum: ESCALATED_DATES_ENUM
  },
  familyType: {
    name: 'family-type',
    friendlyName: 'Family',
    type: 'family'
  }
};

export const filterAttributes = (attributes, id, datatypeName) => {
  const attributesTypes = ['string', 'color', 'int', 'glidepath_values', 'timestamped_indicator'];
  let attributesNames =  ['targetValueComparator', 'contributors', 'driver', 'glidepathFrequency', 'measurement'];
  if (!id) {
    attributes.unshift(extraAttributes.escalatedOn, extraAttributes.familyType);
    attributesNames = ['type', 'status', ...attributesNames];
  }
  if (id && datatypeName === 'Issue') {
    attributes.unshift(extraAttributes.escalatedOn);
  }
  const filteredData = attributes.filter((attr) => (!attributesTypes.some((type) => type === attr.type) || (attr.enum)))
    .filter((attr) => (!attributesNames.some((type) => type === attr.name)));
  return filteredData;
};

export const filterDataOptionsByType = (attributes, list, id) => {
  const datatypeName = list.dataTypes.value.find((item) => item.id === id)?.name;
  const filteredAttributes = filterAttributes(attributes, id, datatypeName);
  return filteredAttributes.map((attr) => {
    let options = [] ; let maxDisplayedItems = 5; let treeValues = null;
    if (attr.type === 'user' || attr.type === 'list') {
      options = list.allUsers.value.map((user) => ({
        text: `${user.firstname} ${user.lastname}`,
        id: user.id,
        showIcon: false,
        name: attr.name,
        withID: true,
        avatar: {
          picture: user.picture,
          size: 'default',
          color: '#8AC3C5',
          altText: getUserInitials(user.firstname, user.lastname)
        },
        type: 'member'
      })) || [];
      maxDisplayedItems = 2;
    } else if (attr.type === 'level') {
      options = list.allLevels.value;
      treeValues = {labelField: 'level-name', status: 'Level test', showLabelField: true};
    } else if (attr.enum && attr.enum.length) {
      options = attr.enum.map((item) => ({
        text: item.value,
        name: attr.name,
        id: item.id,
        iconName: '',
        type: attr.componentType === 'badge' ? 'badge' : 'menu',
        state: 'default',
        iconSize: 'default',
        withID: attr.type === 'dates'
      })) || [];
    } else if (attr.type === 'family') {
      options = list.dataTypes.value.map((item) => ({
        text: item.name,
        id: item.id,
        name: attr.name,
        iconName: '',
        type: 'menu',
        state: 'default',
        iconSize: 'default',
        withID: true
      })) || [];
    }
    const {name, componentType, friendlyName, hasSingleSelect} = attr;
    return {name, componentType, label: friendlyName, options, maxDisplayedItems, treeValues, hasSingleSelect} ;
  });
};
export const getNext7Days = (item) => {

  const startOfWeek = new Date();
  const dates = [];

  for (let i = 0; i < 7; i++) {
    const toDate = new Date(startOfWeek);
    toDate.setDate(startOfWeek.getDate() + i);
    const date = {
      type: 'custom',
      'end-date': toDate.toISOString().split('T')[0]
    };
    dates.push(date);
  }
  return { ...item, dates };
};

export const incompleteStatusValues = INCOMPLETE_STATUS_LIST.map((status) => status.value);
export const defaultQuickFilters = [
  { name: 'owner', label: t('filter.assignedToMe'),
    icon: 'User', color: 'secondarySoft', hasCurrentUser: true,
    type: 'filled', isFocused: false, size: 'medium', values: []},
  { name: 'escalation-owner', label: t('filter.escalatedToMe'), hasCurrentUser: true,
    icon: 'ArrowUpFromDot', color: 'secondarySoft',
    type: 'filled', isFocused: false, size: 'medium', values: []},
  { name: 'due-date', label: t('filter.dueNext', {dayNumber: 7}),
    icon: 'CalendarClock', color: 'secondarySoft',
    type: 'filled', isFocused: false, size: 'medium', values: getNext7Days().dates},
  { name: 'status', label: t('filter.incomplete'),
    icon: 'CircleDashed', color: 'secondarySoft',
    type: 'filled', isFocused: false, size: 'medium', values: incompleteStatusValues}
];
export const ATTRIBUTES_WITH_HISTORY = {
  'escalated-on': {
    attributeHistoryTraces: 'escalation-owner'
  }
};

export const getdataTypeIdFromName = (name, dataTypes) => dataTypes.find((datatype) => datatype.name === name)?.id;
export const buildMyWorksFilters = (currentUserId, dataTypes) => ({
  due: {
    path: '/elements',
    quickFilters: {
      'due-date': getNext7Days().dates,
      owner: [currentUserId],
      status: incompleteStatusValues
    }
  },
  escalations: {
    path: `/elements/${getdataTypeIdFromName('Issue', dataTypes)}`,
    quickFilters: {
      'escalation-owner': [currentUserId],
      status: incompleteStatusValues
    },
    otherFilters: {
      'escalated-on': ['last_7days']
    }
  },
  assignedToMe: {
    path: '/elements',
    quickFilters: {
      owner: [currentUserId],
      status: incompleteStatusValues
    }
  }
});
