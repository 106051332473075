import {HORIZON_TYPES, URL_PATTERN} from 'GLOBALS/constants';
import i18n from 'PLUGINS/locales/i18n';

/**
 * Calculates the difference in days between two dates.
 * @param {Date} date1 The Starting date.
 * @param {Date} date2 The end date.
 * @returns {number} The difference in days between the two dates.
 */
export const datesDiffInDays = (date1, date2) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY));
};

/**
 * Formats a date relative to the current date.
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted relative date.
 */
export const formatRelativeDate = (date) => {
  const currentDate = new Date();
  const diffDays = datesDiffInDays(date, currentDate);
  if (diffDays === 0) {
    return 'today';
  } else if (diffDays === 1) {
    return 'yesterday';
  } else {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
};

/**
 * Checks if a given string is a valid date.
 *
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns `true` if the string is a valid date, otherwise returns `false`.
 */
export const isDate = (str) => {
  // Try parsing the string as a date
  const date = new Date(str);
  // Check if the parsed date is valid and not equal to "Invalid Date"
  return !isNaN(date) && date.toString() !== 'Invalid Date';
};

/**
 * Activity details based on modification and creation dates.
 * @typedef {Object} ActivityDetails
 * @property {string} formatedDate - The formatted date.
 * @property {string} activityStatus - The activity status ('edited' or 'created').
 */

/**
 * Returns the activity details based on modification and creation dates.
 *
 * @param {string} modificationDate - The modification date.
 * @param {string} creationDate - The creation date.
 * @returns {ActivityDetails} - The activity details object.
 */
export const getActivity = (modificationDate, creationDate) => {
  const formatedDate = formatRelativeDate(new Date(modificationDate));
  const datesDifference = new Date(modificationDate) - new Date(creationDate);
  const activityStatus = datesDifference > 1000 ? 'edited' : 'created';
  return { formatedDate, activityStatus };
};

/**
 * Returns the user's initials.
 * @param {String} firstname The user's firstname
 * @param {String} lastname The user's lastname
 * @returns {String} The user's initials
 */
export function getUserInitials(firstname, lastname) {
  return `${(firstname || '').charAt(0).toUpperCase()}${(lastname || '').charAt(0).toUpperCase()}`;
}

/**
 * Checks if two objects have the same keys and values.
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns true if both objects have the same keys and values, false otherwise.
 *
 * @example
 * // returns true
 * objectsAreEqual({a: 1, b: 2}, {a: 1, b: 2});
 *
 * @example
 * // returns false
 * objectsAreEqual({a: 1, b: 2}, {a: 1, b: 3});
 *
 * @example
 * // returns false
 * objectsAreEqual({a: 1, b: 2}, {a: 1, c: 2});
 */
export function objectsAreEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
}

/**
 * Compares two objects for equality based on their keys and values.
 *
 * This function checks that both objects contain the same keys and that
 * the values associated with those keys are identical. It returns false
 * if any key-value pair is different or if there are keys present in
 * the second object that are not in the first.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns true if both objects are equal in terms
 *                     of keys and values, false otherwise.
 *
 * @example
 * const objA = { a: 1, b: 2, c: 3 };
 * const objB = { a: 1, b: 2, c: 3 };
 * console.log(compareObjects(objA, objB)); // true
 *
 * const objC = { a: 1, b: 2, d: 4 };
 * console.log(compareObjects(objA, objC)); // false
 *
 * const objD = { a: 1, b: 3, c: 3 };
 * console.log(compareObjects(objA, objD)); // false
 */
export function compareObjects(obj1, obj2) {
  // Check for keys that exist only in obj2
  for (const key in obj2) {
    if (!(key in obj1)) {
      return false;
    }
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const commonKeys = keys1.filter((key) => keys2.includes(key));
  for (const key of commonKeys) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

/**
 * Checks if a specific feature is enabled in the given features list.
 *
 * @param {Array} featuresList - The list of features.
 * @param {string} featureName - The name of the feature to check.
 * @returns {boolean} - Returns true if the feature is enabled, otherwise false.
 */
export const isFeatureEnabled = (featuresList, featureName) => !!featuresList?.[featureName];

/**
 * Compares two objects for deep equality based on their keys and values.
 *
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns true if both objects are equal in terms
 *                     of keys and values, false otherwise.
 */
export const compareProxies = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !compareProxies(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
/**
 * Formats a date relative to the i18n format.
 *
 * @param {Date} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @param {string} formatKey - The i18n key for the date format.
 * @returns {string} The formatted date.
 */
export const formatDateFromI18n = (date, locale, formatKey) => {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error('Invalid date object.');
  }
  // Get the format string from i18n, or fall back to "en" format
  const dateFormat =
  i18n.global.t(formatKey, { locale }) // Check if the key exists
    ?? i18n.global.t(formatKey, { locale: 'en' }); // Default to "en"
  // Define parts of the date
  const formatter = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: locale !== 'en-US' ? 'h23' : 'h12' // 24-hour format for other locales
  });

  // Extract date parts
  const parts = formatter.formatToParts(date);
  const partsMap = Object.fromEntries(parts.map((part) => [part.type, part.value]));

  // Replace placeholders in the format string
  const formattedDate = dateFormat
    .replace('a', partsMap.dayPeriod ?? '')
    .replace('dd', partsMap.day)
    .replace('MM', partsMap.month)
    .replace('YYYY', partsMap.year)
    .replace('HH', partsMap.hour)
    .replace('mm', partsMap.minute);

  return formattedDate;
};

export const isValidUrl = (url) => URL_PATTERN.test(url);

/**
 * Converts a given date to a UTC date with specified hours, minutes, and seconds.
 *
 * @param {Date|string} date - The date to convert. Can be a Date object or a date string.
 * @param {number} [hh=0] - The hours to set in the UTC date. Defaults to 0.
 * @param {number} [mm=0] - The minutes to set in the UTC date. Defaults to 0.
 * @param {number} [ss=0] - The seconds to set in the UTC date. Defaults to 0.
 * @returns {Date|null} - The converted UTC date, or null if the input date is invalid.
 */
export const utcDate = (date, hh = 0, mm = 0, ss = 0, ms = 0) => {
  if (!date) {
    return null;
  }
  const baseDate = typeof date === 'string' ? new Date(date) : date;

  if (isNaN(baseDate.getTime())) {
    return null;
  }
  return new Date(Date.UTC(
    baseDate.getUTCFullYear(),
    baseDate.getUTCMonth(),
    baseDate.getUTCDate(),
    hh, // Hours
    mm, // Minutes
    ss,  // Seconds
    ms // Milliseconds
  ));
};

/**
 * Encodes a URL by replacing certain characters with their HTML entity equivalents.
 *
 * @param {string} url - The URL to be encoded.
 * @returns {string} The encoded URL with HTML entities.
 */
export function encodeURL(url) {
  const htmlSanitizationMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#x27;'
  };
  return (url && typeof url === 'string') ? url.replace(/(&)|(<)|(>)|(")|(')/ig, (s) => htmlSanitizationMap[s]) : '';
}

/**
 * Unsanitizes a given URL by replacing HTML entities with their corresponding characters.
 *
 * @param {string} url - The URL string to be unsanitized.
 * @returns {string} - The unsanitized URL string.
 */
export function unsanitizeUrl(url) {
  const htmlUnsanitizationMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': '\''
  };
  return (url && typeof url === 'string') ? url.replace(/(&amp;)|(&lt;)|(&gt;)|(&quot;)|(&#x27;)/ig, (s) => htmlUnsanitizationMap[s]) : '';
}

/**
 * Sanitizes a given URL by trimming whitespace and encoding it.
 *
 * @param {string} url - The URL to be sanitized.
 * @returns {string} - The sanitized URL, or an empty string if the input is not a valid string.
 */
export function sanitizeUrl(url) {
  return (url && typeof url === 'string') ? encodeURL(unsanitizeUrl(url.trim())) : '';
}

/**
 * Returns a time window (start date and end date) based on the provided horizon object.
 * @param {Object} horizon - Object containing the configuration of the time window.
 * @param {string} createdOn - Creation date in ISO format (e.g., '2024-01-15').
 * @returns {Object} - Object containing the properties startDate and endDate.
 */
export const getKPITimeWindow = (horizon, createdOn) => {
  if (horizon.type === HORIZON_TYPES.CUSTOM) {
    return {
      startDate: horizon['start-date'],
      endDate: horizon['end-date']
    };
  } else if (horizon.type === HORIZON_TYPES.STANDARD) {
    const createdDate = new Date(createdOn);
    const endDate = new Date(Date.UTC(horizon.year, horizon.period, 0));

    return {
      startDate: createdDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0]
    };
  } else {
    throw new Error('Unsupported horizon type');
  }
};
