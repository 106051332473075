<template>
  <div>
    <slot v-if="authStore.authenticated" />
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useAuthStore } from 'SRC/piniaStore/auth/auth';
import { useUsersStore } from 'SRC/piniaStore/users/users';

export default {
  name: 'FetchDataWrapper',
  data() {
    return { undoManager: {} };
  },
  computed: {
    ...mapStores(useAuthStore, useUsersStore)
  },
  watch: {
    authStore: {
      async handler(authStore) {
        if (authStore.authenticated) {
          await this.fetchUsers();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async fetchUsers()  {
      const promise = (fn) =>
        new Promise((resolve, reject) => {
          fn().then((result) => {
            if (!result) {
              reject({ error: 'Error occurred in fetch data' });
            }
            if ((result && result.error)) {
              reject(result);
            } else {
              resolve(result);
            }
          });
        });
      try {
        await Promise.all([
          promise(this.usersStore.fetchCurrentUser),
          promise(this.usersStore.fetchAllUsers)
        ]);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

