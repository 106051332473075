import MainLayout from 'SRC/layouts/MainLayout/MainLayout.vue';
import BoardTableView from 'SRC/views/BoardTable.vue';
import DataTableView from 'SRC/views/DataView/DataTable.vue';
import RoomTableView from 'SRC/views/RoomTable.vue';
import HomeView from 'SRC/views/HomePage.vue';
import PageNotFound from 'SRC/views/PageNotFound/PageNotFound.vue';
import UnavailableServiceView from 'SRC/views/UnavailableService/UnavailableService.vue';
import ImportHierarchyView from 'SRC/views/ImportHierarchy/ImportHierarchy.vue';
import HierarchyView from 'SRC/views/HierarchyView/HierarchyView.vue';

const routes = [
  { path: '/',
    redirect: '/home',
    component: MainLayout,
    children: [
      {
        path: 'boards',
        component: BoardTableView
      },
      {
        path: 'rooms',
        component: RoomTableView
      },
      {
        path: 'home',
        component: HomeView
      }, {
        path: 'elements/:id?',
        component: DataTableView
      },
      {
        path: '/hierarchy-import',
        component: ImportHierarchyView
      },
      {
        path: 'hierarchy',
        component: HierarchyView
      }
    ]
  },
  { path: '/unavailableService', component: UnavailableServiceView },
  {
    path: '/:catchAll(.*)',
    component: PageNotFound
  }
];

export default routes;
