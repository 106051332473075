<template>
  <div class="RoomTable">
    <div class="RoomTable-header">
      <IobHeaderAction
        :title="$t('rooms.label')"
        :sub-title="roomsCount"
        :search-placeholder="$t('rooms.search')"
        button-icon="Plus"
      />
    </div>
    <div class="RoomTable-body" />
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity';

const roomsCount = ref(12);
</script>

<style lang="scss" scoped>
.RoomTable-body {
  padding: var(--size-tokens-structure-gap-6x, 24px) var(--size-tokens-structure-page-padding-horizontal, 24px);
}
</style>
