import { post } from 'SRC/api/index.js';
import { defineStore } from 'pinia';

const initialState = () => ({
  counter: 0
});

export const useMyWorkStore = defineStore('myWork', {
  state: initialState,
  actions: {
    async searchDatasetElements({query, attributes = {}, dataTypeFilters, historyTraces}) {
      const body = {
        attributes,
        ...(dataTypeFilters && { dataTypeFilters }),
        ...(historyTraces && { historyTraces })
      };
      try {
        const response = await post(`/dataset-elements/search-requests?search=${query}&page=1&size=25`, body);
        if (!response || !response.data) {
          return ;
        }
        this.counter = response.totalCount;
      } catch (error) {
        console.log('Error searching dataset elements', error);
      }
    }
  }
});
