// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/img/no-result.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DataTable[data-v-345a7dad] {\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  gap: var(--app-template-page-gap-main);\n  height: 100%;\n}\n.DataTable-body[data-v-345a7dad] {\n  flex-grow: 1;\n  overflow-y: auto;\n  padding: 0 var(--size-tokens-structure-page-padding-horizontal);\n  z-index: 0;\n}\n.DataTable-body-scrollbar[data-v-345a7dad]::-webkit-scrollbar {\n  width: var(--scrollbar-size-width);\n}\n.DataTable-body-scrollbar[data-v-345a7dad]::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n.DataTable-body-scrollbar[data-v-345a7dad]::-webkit-scrollbar-thumb {\n  border-radius: var(--scrollbar-radius);\n  background: var(--scrollbar-color-scroller);\n}\n.DataTable-container[data-v-345a7dad] {\n  display: flex;\n  padding: 48px 32px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n  background: #f9f9fa;\n  flex-shrink: 0;\n}\n.DataTable-container-img[data-v-345a7dad] {\n  width: 194.048px;\n  height: 225px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n}\n.DataTable-container-message[data-v-345a7dad] {\n  overflow: hidden;\n  color: var(--gray-500, #787391);\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  font-family: \"Noto Sans\", sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0.14px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
