import {defineStore} from 'pinia';
import {useAppStore} from '../app/app';
import {useDataStore} from '../data/data';
import {useUsersStore} from '../users/users';
import {put} from '../../api/index';
import {getUserInitials, isFeatureEnabled} from 'SRC/utils/utils';
import {useTimeSeriesStore} from 'SRC/piniaStore/timeSeries/timeSeries';
import {DATA_TYPES_NAMES, FEATURES} from 'GLOBALS/constants';

function createRelationObject(datasetElement, titleAttributeName) {
  const usersStore = useUsersStore();
  const user = datasetElement.attributes.owner ? usersStore.getUserInfo(datasetElement.attributes.owner) : null;
  return {
    ...datasetElement,
    id: datasetElement.id,
    typeId: datasetElement.typeId,
    friendlyId: datasetElement.attributes['friendly-id'],
    title: datasetElement.attributes[titleAttributeName],
    attributes: datasetElement.attributes,
    ownerPicture: user ? user.picture : null,
    ownerName: user ? getUserInitials(user.firstname, user.lastname) : null,
    typeName: datasetElement.typeName
  };
}

export const useEditorStore = defineStore('editor', {
  state: () => ({
    dataType: null,
    datasetElementId: null,
    isEditorOpen: false,
    previousDatasetElementIds: [],
    selectedDatasetElement: null,
    isCreating: false,
    timeSeriesPoints: []
  }),
  actions: {
    async initEditor(datasetElementId) {
      this.datasetElementId = datasetElementId;
      const appStore = useAppStore();
      const dataStore = useDataStore();
      const timeSeriesStore = useTimeSeriesStore();
      let datasetElement = dataStore.datasetElements[datasetElementId];
      if (!datasetElement) {
        datasetElement = await dataStore.fetchDatasetElementById(datasetElementId);
      }
      this.selectedDatasetElement = datasetElement;
      this.dataType = appStore.datatypes[datasetElement.typeId];

      const timeSeriesId = this.selectedDatasetElement.attributes.timeseries;
      const canUseTimeSeriesData = isFeatureEnabled(appStore.featureToggles, FEATURES.KPI_HISTORIZATION_FEATURE);
      if (canUseTimeSeriesData && this.dataType.name === DATA_TYPES_NAMES.KPI && timeSeriesId !== undefined) {
        await timeSeriesStore.fetchTimeSeriesActualValue(timeSeriesId, this.selectedDatasetElement);
        this.timeSeriesPoints = await timeSeriesStore.fetchTimeSeries(timeSeriesId);
      }
    },
    resetEditor() {
      this.dataType = null;
      this.datasetElementId = null;
      this.timeSeriesPoints = [];
    },
    resetPreviousDataEditor() {
      this.previousDatasetElementIds = [];
    },
    closeEditor() {
      this.isEditorOpen = false;
      this.resetEditor();
    },
    async openEditor(datasetElementId) {
      this.initEditor(datasetElementId);
      this.isEditorOpen = true;
    },
    async openPreviousDetailsEditor() {
      const previousDatasetElementId = this.previousDatasetElementIds.pop();
      if (!previousDatasetElementId) {
        return ;
      }
      this.openEditor(previousDatasetElementId);
    },
    async updateDatasetElement(attributes) {
      const dataStore = useDataStore();
      const datasetElement = dataStore.datasetElements[this.datasetElementId] || this.selectedDatasetElement;
      attributes = {...datasetElement.attributes, ...attributes};
      const computedByFormulaAttrsList = this.dataType.attributes.filter((attr) => attr.computedByFormula).map((attr) => attr.name);
      computedByFormulaAttrsList.forEach((attrName) => {
        delete attributes[attrName];
      });
      const newBody = Object.assign({}, datasetElement, {attributes, children: {}});
      delete newBody.children;
      const updatedDatasetElement = await put(`/dataset-elements/${this.datasetElementId}`, newBody);
      if (!updatedDatasetElement) {
        return;
      }
      this.selectedDatasetElement = updatedDatasetElement;
      if (dataStore.datasetElements[this.datasetElementId]) {
        dataStore.updateDatasetElements(updatedDatasetElement);
      }
    },
    async addPreviousDatasetElement(datasetElementId) {
      this.previousDatasetElementIds.push(datasetElementId);
    },
    // TODO: Move in timeSeries store
    async createTimeSeriesPoint(timestamp, value, valueType) {
      const timeSeriesStore = useTimeSeriesStore();
      const dataStore = useDataStore();
      const timeSeriesData = {
        timestamp,
        valueType,
        value
      };
      const timeSeriesPoint = await timeSeriesStore.createTimeSeries(timeSeriesData, this.selectedDatasetElement);
      this.timeSeriesPoints.push(timeSeriesPoint);

      const datasetElement = this.selectedDatasetElement || dataStore.datasetElements[this.datasetElementId];

      if (datasetElement) {
        const updatedAttributes = {
          ...datasetElement.attributes,
          timeseries: timeSeriesPoint.id
        };

        await this.updateDatasetElement(updatedAttributes);
      }
    },
    // TODO: Move in timeSeries store
    async addOrUpdateTimeSeriesPoint(id, timestamp, value, valueType) {
      const timeSeriesStore = useTimeSeriesStore();

      const existingPoint = this.timeSeriesPoints.find((point) => point.timestamp === timestamp);

      if (existingPoint) {
        const timeSeriesData = {
          valueType,
          value
        };
        const updatedTimeSeriesPoint = await timeSeriesStore
          .updateTimeSeriesPoint(id, existingPoint.timestamp, timeSeriesData, this.selectedDatasetElement);
        this.timeSeriesPoints = this.timeSeriesPoints.map((point) =>
          point.timestamp === existingPoint.timestamp ? updatedTimeSeriesPoint : point
        );

      } else {
        const timeSeriesData = {
          timestamp,
          valueType,
          value
        };
        const timeSeriesId = this.selectedDatasetElement.attributes.timeseries;
        const timeSeriesPoint = await timeSeriesStore.addTimeSeriesPoint(timeSeriesId, timeSeriesData, this.selectedDatasetElement);
        this.timeSeriesPoints.push(timeSeriesPoint);
      }
    }
  },

  getters: {
    getRelationObject() {
      const appStore = useAppStore();
      return (datasetElement) => {
        const titleAttribute = appStore.getTitleAttribute(datasetElement.typeId);
        return createRelationObject(datasetElement, titleAttribute.name);
      };
    }
  }
});
